import React, { Component } from 'react';

import './ServiceTwo.scss';

class ServiceTwo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    const inlineStyle = {
      backgroundImage: 'url(' + publicUrl + '/assets/img/bg/5.png)',
    };

    return (
      <div
        className="service-area service-area-about pb-xl-5 pd-0"
        style={inlineStyle}
      >
        <div className="container">
          <h3
            className="text-center main-color"
            style={{ marginBottom: '3.125rem', fontSize: '2.375rem' }}
          >
            Saiba o que podemos fazer por você
          </h3>

          <div className="service-slider-2 row">
            {this.props.items.map((item, i) => (
              <div key={i} className="single-intro text-center my-own-card">
                <div className="thumb">
                  <img src={publicUrl + item.icon} alt="icones ilustrativos" />
                </div>
                <div className="details">
                  {item.title && (
                    <h4
                      className="title"
                      style={{ color: 'var(--main-color-one)' }}
                    >
                      {item.title}
                    </h4>
                  )}
                  <p>{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceTwo;
