import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../components/global-components/Header/Header';
import Navbar from '../components/global-components/Navbar/Navbar';
import PageInfos from '../components/global-components/PageInfos/PageInfos.jsx';
import PropertyDetailsSection from '../components/section-components/PropertyDetailsSection/PropertyDetailsSection.jsx';
import PropertyValuesAndInfos from '../components/section-components/PropertyValuesAndInfos/PropertyValuesAndInfos';
import PropertyDescription from '../components/section-components/PropertyDescription/PropertyDescription';
import RecomandProperties from '../components/section-components/RecomandProperties/RecomandProperties.jsx';
import OurSocialMedias from '../components/section-components/our-social-medias';
import Footer from '../components/global-components/Footer/Footer.jsx';
import { ReactComponent as LoadingIcon } from './../assets/icons/loading-icon.svg';

import { Api } from '../Api';
import { htmlToString } from '../helpers/htmlToString';

function getAndSetProperty(setProperty, propertyId) {
  Api.getPropertyById(propertyId)
    .then((response) => {
      const property = response[0];
      setProperty(property);
    })
    .catch((err) => console.log('err', err));
}

function goPageTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function getAndSetPost(
  neighborhoodPosts,
  setNeighborhoodPosts,
  property,
  setPost
) {
  Api.getBlogNeighborhoodPosts().then((data) => {
    setNeighborhoodPosts(data.posts);

    const filteredPost =
      property?.address_district &&
      neighborhoodPosts.filter((post) =>
        post.title.includes(property.address_district)
      );

    setPost(filteredPost);
  });
}

const PropertyDetails = () => {
  const [property, setProperty] = useState(null);
  const [propertyType, setPropertyType] = useState();
  const [neighborhoodPosts, setNeighborhoodPosts] = useState([]);
  const [post, setPost] = useState([]);
  const url = window.location.href;

  const location = useLocation();
  const params = useParams();
  goPageTop();

  useEffect(() => {
    if (property) {
      if (
        property.subtype === 'terreno' ||
        property.subtype === 'terreno em condominio'
      ) {
        setPropertyType('terreno');
      } else if (
        property.subtype === 'apartamento' ||
        property.subtype === 'sala comercial'
      ) {
        setPropertyType('apartamento');
      } else {
        setPropertyType('casa');
      }
    }
  }, [property]);

  useLayoutEffect(() => {
    getAndSetProperty(setProperty, params.id);
  }, [location, params.id]);

  useLayoutEffect(() => {
    getAndSetPost(neighborhoodPosts, setNeighborhoodPosts, property, setPost);
  }, [property]);

  const getDescription = (description) => {
    if (!description) return '';
    if (description.length > 150)
      return `${htmlToString(description).slice(0, 147)}...`;

    return description;
  };

  if (property && property?.status !== 1) {
    window.location.href = '/';
  }

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        {property && <title>{`Moysés Imóveis - ${property?.title}`}</title>}
        {property && <meta property="og:title" content={property?.title} />}
        {property && (
          <meta
            property="og:image"
            content={`https://cms.moysesimoveis.com.br/upload/imovel/${
              property?.cover || property?.images[0]
            }`}
          />
        )}
        {property && (
          <meta
            property="og:description"
            content={getDescription(property?.description)}
          />
        )}
        <meta property="og:url" content={url} />

        <meta name="twitter:card" content="summary_large_image" />
        {property && (
          <meta property="twitter:title" content={property?.title} />
        )}
        {property && <meta name="twitter:title" content={property?.title} />}
        {property && (
          <meta
            name="twitter:description"
            content={getDescription(property?.description)}
          />
        )}
        {property && (
          <meta
            name="twitter:image"
            content={`https://cms.moysesimoveis.com.br/upload/imovel/${
              property?.cover || property?.images[0]
            }`}
          />
        )}
      </Helmet>

      <div>
        <Header />
        <Navbar />
        <PageInfos currentPageName="Detalhes do Imóvel" />
        {property ? (
          <>
            <PropertyDetailsSection property={property} />
            <PropertyValuesAndInfos
              propertyType={propertyType}
              property={property}
            />
            <PropertyDescription property={property} post={post} />
            <RecomandProperties property={property} />
            <OurSocialMedias />
          </>
        ) : (
          <div className="container">
            <LoadingIcon />
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default PropertyDetails;
