import React from 'react';
import CookiesBar from './components/global-components/CookiesBar/CookiesBar';
import { PropertiesProvider } from './context/properties';
import { Routes } from './routes';

function App() {
  return (
    <PropertiesProvider>
      <Routes />
      <CookiesBar />
    </PropertiesProvider>
  );
}

export { App };
