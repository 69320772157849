import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

import ReactLoading from 'react-loading';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { withStyles } from '@mui/styles';

import ShareIcon from '@material-ui/icons/Share';
import MuiWhatsAppIcon from '@material-ui/icons/WhatsApp';
import MuiFacebookIcon from '@material-ui/icons/Facebook';
import MuiLinkedInIcon from '@material-ui/icons/LinkedIn';
import MuiTwitterIcon from '@material-ui/icons/Twitter';
import MuiInstagramIcon from '@material-ui/icons/Instagram';
import MuiLinkIcon from '@material-ui/icons/Link';

import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from 'react-share';

import { Api } from '../../../Api';

import Card from '../Card/Card';

import './NewsDetails.scss';
import { Helmet } from 'react-helmet';
import { htmlToString } from '../../../helpers/htmlToString';

const NewsDetails = ({ post, setPost }) => {
  const key = 'AIzaSyC6LsA9sr67s3Ml46aM8qIeIg7nI8AlWms';
  const url = window.location.href;

  const { pathname } = useLocation();

  const start = pathname.indexOf('post');
  const bar = pathname.indexOf('/', start);
  const secondBar = pathname.indexOf('/', bar + 1);
  const number = pathname.slice(bar + 1, secondBar);
  const [anchorEl, setAnchorEl] = useState(null);
  const [speech, setSpeech] = useState(undefined);
  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);

  const [otherPosts, setOtherPosts] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function copyText(entryText) {
    navigator.clipboard.writeText(entryText);
    handleClose();
  }

  const handlePlay = () => {
    if (!play) {
      speech.play();
      setPlay(true);
    } else {
      speech.pause();
      setPlay(false);
    }
  };

  function replaceImagesUrl(body) {
    let parsedBody = body.replaceAll(
      'https://www.moysesimoveis.com.br/upload',
      'https://cms.moysesimoveis.com.br/upload'
    );

    parsedBody = parsedBody.replaceAll(
      'https://moysesimoveis.com.br/upload',
      'https://cms.moysesimoveis.com.br/upload'
    );

    return parsedBody;
  }

  function getPost() {
    Api.getBlogPost(number).then((data) => {
      const dataImgsParsed = {
        ...data,
        body: replaceImagesUrl(data.body),
      };

      return setPost(dataImgsParsed);
    });
  }

  function getPosts() {
    Api.getBlogPosts(5).then(({ posts }) => {
      let filteredPosts = posts
        .filter((p) => p.id !== parseInt(number))
        .slice(0, 4);
      setOtherPosts(filteredPosts);
    });
  }
  const formattedDate = post?.created_at.replace('Z', '');

  const date = new Date(formattedDate).toLocaleString('pt-br', {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  const cleanText = sanitizeHtml(post?.body, {
    allowedTags: [],
    allowedAttributes: {},
  });

  function getSpeech() {
    axios
      .post(
        `https://texttospeech.googleapis.com/v1/text:synthesize?key=${key}`,
        {
          input: {
            text: cleanText,
          },
          voice: {
            languageCode: 'pt-br',
            ssmlGender: 'female',
          },
          audioConfig: { audioEncoding: 'MP3' },
        }
      )
      .then(function (response) {
        const { audioContent } = response.data;
        setSpeech(new Audio(`data:audio/x-wav;base64, ${audioContent}`));
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function requestAudio() {
    setLoading(true);
    getSpeech();
  }

  const getDescription = (description) => {
    if (!description) return '';
    if (description.length > 150)
      return `${htmlToString(description).slice(0, 147)}...`;

    return description;
  };

  useEffect(() => {
    getPost();
    getPosts();
  }, [number]);

  return (
    <>
      <Helmet>
        <title>{`Moysés Imóveis - Blog - ${post?.title}`}</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post?.title} />
        <meta
          property="og:image"
          key="og:image"
          content={`https://cms.moysesimoveis.com.br/upload/artigos/${post?.image}`}
        />
        <meta
          property="og:description"
          content={getDescription(post?.description)}
        />
        <meta property="og:url" content={url} />
        <meta property="twitter:title" content={post?.title} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post?.title} />
        <meta
          name="twitter:description"
          content={getDescription(post?.description)}
        />
        <meta
          name="twitter:image"
          content={`https://cms.moysesimoveis.com.br/upload/artigos/${post?.image}`}
        />
      </Helmet>
      <div className="news-details-area">
        <div className="container">
          <div className="row justify-content-center pd-top-70">
            <div className="col-lg-8">
              <div className="news-details-wrap">
                <h3 className="title1">{post?.title}</h3>
                <div className="line">
                  <div className="author">
                    <p>{date}</p>
                  </div>
                  {cleanText?.length <= 5000 && (
                    <div className="text-to-speech">
                      {loading ? (
                        <ReactLoading
                          type="spinningBubbles"
                          color="rgba(0, 0, 0, 0.54)"
                          height={24}
                          width={24}
                        />
                      ) : !speech ? (
                        <IconButton
                          onClick={() => requestAudio()}
                          title="Audiodescrição"
                        >
                          <VolumeUpIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handlePlay()}>
                          {!play ? (
                            <PlayCircleFilledWhiteIcon />
                          ) : (
                            <PauseCircleFilledIcon />
                          )}
                        </IconButton>
                      )}
                    </div>
                  )}

                  <div>
                    <IconButton aria-haspopup="true" onClick={handleClick}>
                      <ShareIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <StyledMenuItem onClick={handleClose}>
                        <WhatsappShareButton url={url}>
                          <WhatsAppIcon />
                        </WhatsappShareButton>
                      </StyledMenuItem>
                      <StyledMenuItem onClick={handleClose}>
                        <FacebookShareButton url={url}>
                          <FacebookIcon />
                        </FacebookShareButton>
                      </StyledMenuItem>

                      <StyledMenuItem onClick={handleClose}>
                        <TwitterShareButton url={url}>
                          <TwitterIcon />
                        </TwitterShareButton>
                      </StyledMenuItem>
                      <StyledMenuItem onClick={handleClose}>
                        <LinkedinShareButton url={url}>
                          <LinkedInIcon />
                        </LinkedinShareButton>
                      </StyledMenuItem>
                      <StyledMenuItem onClick={() => copyText(url)}>
                        <LinkIcon />
                      </StyledMenuItem>
                    </Menu>
                  </div>
                </div>

                <div>{post && parse(post.body)}</div>
              </div>
            </div>
            <div className="more-news">
              <h3>Veja Também</h3>
              <div className="cards">
                {otherPosts?.map((post, i) => (
                  <Card key={i} post={post} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledMenuItem = withStyles(() => ({
  root: {
    fontFamily: 'var(--heading-font)',
  },
}))(MenuItem);

const WhatsAppIcon = withStyles(() => ({
  root: {
    color: '#25D366',
  },
}))(MuiWhatsAppIcon);

const FacebookIcon = withStyles(() => ({
  root: {
    color: '#4267B2',
  },
}))(MuiFacebookIcon);

const LinkedInIcon = withStyles(() => ({
  root: {
    color: '#0077B5',
  },
}))(MuiLinkedInIcon);

const TwitterIcon = withStyles(() => ({
  root: {
    color: '#1DA1F2',
  },
}))(MuiTwitterIcon);

const InstagramIcon = withStyles(() => ({
  root: {
    color: '#E1306C',
  },
}))(MuiInstagramIcon);

const LinkIcon = withStyles(() => ({
  root: {},
}))(MuiLinkIcon);

export default NewsDetails;
