import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";

import InputDoubleSlider from "../InputDoubleSlider/InputDoubleSlider";
import { FilterModal } from "./../FilterModal/FilterModal.jsx";

import bannerBackground from "./../../../assets/banner-background.png";

import { Api } from "../../../Api";

import "./Banner.scss";
import { useMinMaxValues } from "../../../context/properties";
import { propertyTypes } from "../../../data/propertyTypes";
import {
  MultiSelect,
  StyledOption,
  BpCheckedIcon,
  BpIcon,
} from "../../global-components/MultiSelect/MultiSelect";
import { Checkbox } from "@mui/material";

const dorms = {
  1: "1 Dormitório",
  2: "2 Dormitórios",
  3: "3 Dormitórios",
  4: "4 Dormitórios",
  5: "5 Dormitórios",
};

function Banner() {
  const [districts, setDistricts] = useState([]);
  const { minMaxPropertyValues, maxPrice } = useMinMaxValues();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [advancedFilterValues, setAdvancedFilterValues] = useState([]);

  const history = useHistory();

  function getDistricts(type, subType) {
    Api.getAvailableDistricts(type, subType).then((data) => {
      let districtsData = data.map((district) => ({
        id: district.id,
        name: district.title,
      }));

      setDistricts(districtsData);
    });
  }
  const [propertyValue, setPropertyValue] = useState(minMaxPropertyValues);

  const propertySliderStep = 100000;
  const propertySliderName = "propertyValue";

  const initialValues = {
    code: "",
    stage: "",
    category: "",
    type: [],
    district: [],
    bedroom: [],
    garage: "",
    filters: [],
    propertyValue: minMaxPropertyValues || [0, 5000000],
  };

  const serialize = (obj) =>
    Object.entries(obj)
      .map(([k, v]) => {
        if (v === "") return null;
        if (k === "propertyValue") {
          return `minPrice=${v[0]}&maxPrice=${v[1]}`;
        }
        if (v.length === 0) {
          return null;
        }

        if (k === "type") {
          return `type=${v.join()}`;
        }
        if (k === "filters") {
          return `filters=${v.join()}`;
        }

        return `${k}=${v}`;
      })
      .filter(Boolean)
      .join("&");

  const { handleChange, handleSubmit, setFieldValue, values, isSubmitting } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values) => {
        const newValues = {
          ...values,
          propertyValue: [
            values.propertyValue[0],
            values.propertyValue[1] === 5000000
              ? maxPrice
              : values.propertyValue[1],
          ],
        };

        history.push({
          pathname: "/imoveis",
          search: serialize(newValues),
        });
      },
    });

  const inlineStyle = {
    backgroundImage: `url(${bannerBackground})`,
  };

  function handleCategorySelect(e) {
    handleChange(e);
    setFieldValue("type", "");
    setFieldValue("district", []);
  }

  useEffect(() => {
    setFieldValue("filters", advancedFilterValues);
  }, [advancedFilterValues]);

  useEffect(() => {
    setPropertyValue(minMaxPropertyValues);
  }, [minMaxPropertyValues]);

  useEffect(() => {
    if (!isSubmitting) {
      if (values.type) {
        getDistricts(values.category, values.type.join());
      } else if (values.category && !values.type) {
        getDistricts(values.category);
      } else if (!values.category && !values.type) {
        getDistricts();
      }
    }
  }, [values.category, values.type]);

  function selectedCategory(value) {
    if (value.value === values.category) {
      return value.subTypes;
    }
  }

  return (
    <>
      <div
        className="banner-area jarallax pd-top-responsive"
        style={inlineStyle}
      >
        <div className="container">
          <div className="banner-inner-wrap">
            <div className="row">
              <div className="col-12">
                <div className="banner-inner">
                  <h1 className="title">
                    Busca simples, segura e <br /> <strong>prazerosa!</strong>
                  </h1>
                  <h5 className="sub-title">
                    Sejam imóveis residenciais ou comerciais, realize uma busca
                    de maneira rápida e intuitiva em nosso site e encontre o seu
                    próximo imóvel.
                  </h5>
                </div>
              </div>
              <div className="col-12">
                <div className="banner-search-wrap">
                  <form onSubmit={handleSubmit}>
                    <ul className="nav nav-tabs rld-banner-tab">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#tabs_1"
                        >
                          Para comprar
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#tabs_2"
                        >
                          Buscar por código
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="tabs_1">
                        <div className="rld-main-search">
                          <div className="row banner-row">
                            <div className="banner-stage">
                              <div className="rld-single-select">
                                <select
                                  name="stage"
                                  onChange={handleChange}
                                  className="select single-select"
                                >
                                  <option>O que você busca?</option>
                                  <option value="pronto">Pronto</option>
                                  <option value="construcao">
                                    Em construção
                                  </option>
                                  <option value="lancamento">Lançamento</option>
                                  <option value="planta">Planta</option>
                                </select>
                              </div>
                            </div>
                            <div className="banner-category">
                              <div className="rld-single-select">
                                <select
                                  name="category"
                                  value={values.category}
                                  onChange={(e) => handleCategorySelect(e)}
                                  className="select single-select"
                                >
                                  <option value="">Categoria</option>
                                  {propertyTypes.map((type, i) => (
                                    <option key={i} value={type.value}>
                                      {type.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="banner-type">
                              <div className="rld-single-select">
                                <MultiSelect
                                  disabled={!values.category}
                                  className="select single-select"
                                  value={values.type}
                                  onChange={(e) => setFieldValue("type", e)}
                                  renderValue={(selected) => {
                                    let label = selected.map(
                                      (type) => type.label[1]
                                    );
                                    if (selected.length === 0) {
                                      return <option>Tipos</option>;
                                    }
                                    return label.join(", ");
                                  }}
                                >
                                  {propertyTypes
                                    ?.filter(selectedCategory)
                                    .flatMap((type) => type.subTypes)
                                    .map((subType, i) => {
                                      const formattedLabel =
                                        subType.label.replace(/\//g, " / ");

                                      return (
                                        <StyledOption
                                          key={i}
                                          value={subType.value}
                                        >
                                          <Checkbox
                                            sx={{
                                              "&:hover": {
                                                bgcolor: "transparent",
                                              },
                                            }}
                                            disableRipple
                                            color="default"
                                            checkedIcon={<BpCheckedIcon />}
                                            icon={<BpIcon />}
                                            inputProps={{
                                              "aria-label": "Checkbox demo",
                                            }}
                                            checked={
                                              values?.type?.indexOf(
                                                subType.value
                                              ) > -1
                                            }
                                          />

                                          {formattedLabel}
                                        </StyledOption>
                                      );
                                    })}
                                </MultiSelect>
                              </div>
                            </div>

                            <div className="banner-bedrooms">
                              <div className="rld-single-select">
                                <MultiSelect
                                  className="select single-select"
                                  value={values.bedroom}
                                  onChange={(e) => setFieldValue("bedroom", e)}
                                  renderValue={(selected) => {
                                    let label = selected.map(
                                      (bedroom) => bedroom.label[1]
                                    );
                                    if (selected.length === 0) {
                                      return <option>Dormitórios</option>;
                                    }
                                    return label.join(", ");
                                  }}
                                >
                                  {Object.entries(dorms).map(
                                    ([value, label]) => (
                                      <StyledOption key={value} value={value}>
                                        <Checkbox
                                          sx={{
                                            "&:hover": {
                                              bgcolor: "transparent",
                                            },
                                          }}
                                          disableRipple
                                          color="default"
                                          checkedIcon={<BpCheckedIcon />}
                                          icon={<BpIcon />}
                                          inputProps={{
                                            "aria-label": "Checkbox demo",
                                          }}
                                          checked={
                                            values?.bedroom?.indexOf(value) > -1
                                          }
                                        />

                                        {label}
                                      </StyledOption>
                                    )
                                  )}
                                </MultiSelect>
                              </div>
                            </div>

                            <div className="banner-districts">
                              <div className="rld-single-select">
                                <MultiSelect
                                  className="select single-select neighborhood"
                                  value={values.district}
                                  onChange={(e) => setFieldValue("district", e)}
                                  renderValue={(selected) => {
                                    let label = selected.map(
                                      (district) => district.label[1]
                                    );
                                    if (selected.length === 0) {
                                      return <option>Bairros</option>;
                                    }
                                    return label.join(", ");
                                  }}
                                >
                                  {districts?.map((district, i) => {
                                    const formattedLabel =
                                      district.name.replace(/\//g, " / ");

                                    return (
                                      <StyledOption key={i} value={district.id}>
                                        <Checkbox
                                          sx={{
                                            "&:hover": {
                                              bgcolor: "transparent",
                                            },
                                          }}
                                          disableRipple
                                          color="default"
                                          checkedIcon={<BpCheckedIcon />}
                                          icon={<BpIcon />}
                                          inputProps={{
                                            "aria-label": "Checkbox demo",
                                          }}
                                          checked={
                                            values.district.indexOf(
                                              district.id
                                            ) > -1
                                          }
                                        />

                                        {formattedLabel}
                                      </StyledOption>
                                    );
                                  })}
                                </MultiSelect>
                              </div>
                            </div>
                            <div className="banner-price">
                              <InputDoubleSlider
                                minMaxValue={minMaxPropertyValues}
                                visibleValue={propertyValue}
                                setVisibleValue={setPropertyValue}
                                step={propertySliderStep}
                                setFieldValue={setFieldValue}
                                name={propertySliderName}
                                hasMaxSufix={
                                  minMaxPropertyValues[1] === 5000000
                                }
                              />
                            </div>
                          </div>
                          <footer className="row filters-footer">
                            <button
                              type="button"
                              className="btn btn-yellow button-filter"
                              onClick={() => setModalIsOpen(true)}
                            >
                              Mais filtros
                            </button>

                            <button className="btn btn-yellow button-search">
                              Buscar imóvel
                            </button>
                          </footer>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tabs_2">
                        <div className="rld-main-search">
                          <div className="row">
                            <div className="col-xl-12 col-lg-12">
                              <div className="rld-single-input banner-code">
                                <input
                                  name="code"
                                  className="imovel-code"
                                  type="text"
                                  placeholder="Insira o código para buscar o imóvel"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <footer className="row filters-footer-code">
                            <button className="btn btn-yellow button-search">
                              Buscar imóvel
                            </button>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterModal
        history={history}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        advancedFilterValues={advancedFilterValues}
        setAdvancedFilterValues={setAdvancedFilterValues}
      />
    </>
  );
}

export default Banner;
