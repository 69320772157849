import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
} from "react";
import { Api } from "../Api";

const PropertiesContext = createContext();

export function PropertiesProvider({ children }) {
  const [advancedFilters, setAdvancedFilters] = useState([]);

  //local Storage
  const storageSimilarProperties =
    JSON.parse(localStorage.getItem("@MOYSES:properties")) || [];
  const storageSimilarPropertiesUrl =
    localStorage.getItem("@MOYSES:propertiesURL") || "";

  const favoritePropertiesStorage =
    JSON.parse(localStorage.getItem("@MOYSES:favorites")) || [];

  //Min and Max Values
  const [minMaxPropertyValues, setMinMaxPropertyValues] = useState([
    0, 6000000,
  ]);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minMaxMajorArea, setMinMaxMajorArea] = useState([0, 12500]);
  const [minMaxMinorArea, setMinMaxMinorArea] = useState([0, 2000]);

  const [similarProperties, setSimilarProperties] = useState(
    storageSimilarProperties
  );

  const [similarPropertiesUrl, setSimilarPropertiesUrl] = useState(
    storageSimilarPropertiesUrl
  );

  //Favorites Properties
  const [favoriteProperties, setFavoriteProperties] = useState(
    favoritePropertiesStorage
  );

  useLayoutEffect(() => {
    Api.getMinAndMaxPropertiesValues().then((data) => {
      const dataPriceMax = Number(data.price.max);
      setMaxPrice(dataPriceMax);
      const priceMax = dataPriceMax >= 5000000 ? 5000000 : dataPriceMax;

      // Price
      setMinMaxPropertyValues([Number(data.price.min), priceMax]);

      // Area
      setMinMaxMajorArea([
        Number(data.area.minMajorArea),
        Number(data.area.maxMajorArea),
      ]);
      setMinMaxMinorArea([
        Number(data.area.minMinorArea),
        Number(data.area.maxMinorArea),
      ]);
    });
  }, []);

  useEffect(() => {
    localStorage.removeItem("@MOYSES:properties");

    localStorage.setItem(
      "@MOYSES:properties",
      JSON.stringify(similarProperties)
    );
  }, [similarProperties]);

  useEffect(() => {
    localStorage.removeItem("@MOYSES:propertiesURL");
    localStorage.setItem("@MOYSES:propertiesURL", similarPropertiesUrl);
  }, [similarPropertiesUrl]);

  useEffect(() => {
    localStorage.setItem(
      "@MOYSES:favorites",
      JSON.stringify(favoriteProperties)
    );
  }, [favoriteProperties]);

  return (
    <PropertiesContext.Provider
      value={{
        minMaxPropertyValues,
        maxPrice,
        minMaxMajorArea,
        minMaxMinorArea,
        similarProperties,
        setSimilarProperties,
        similarPropertiesUrl,
        setSimilarPropertiesUrl,
        advancedFilters,
        setAdvancedFilters,
        favoriteProperties,
        setFavoriteProperties,
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
}

export function useMinMaxValues() {
  const context = useContext(PropertiesContext);
  const { minMaxPropertyValues, minMaxMajorArea, minMaxMinorArea, maxPrice } =
    context;
  return { minMaxPropertyValues, minMaxMajorArea, minMaxMinorArea, maxPrice };
}

export function useSimilarProperties() {
  const context = useContext(PropertiesContext);
  const {
    similarProperties,
    setSimilarProperties,
    similarPropertiesUrl,
    setSimilarPropertiesUrl,
  } = context;
  return {
    similarProperties,
    setSimilarProperties,
    similarPropertiesUrl,
    setSimilarPropertiesUrl,
  };
}

export function useFavoriteProperties() {
  const context = useContext(PropertiesContext);
  const { favoriteProperties, setFavoriteProperties } = context;
  return { favoriteProperties, setFavoriteProperties };
}
