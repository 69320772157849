import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const PesquisaRedirect = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location.search) {
      history.push('/imoveis');
    }
    if (location.search) {
      const params = location.search
        .replace('stage%5B%5D', 'stage')
        .replace('type', 'category')
        .replace('subtype%5B%5D', 'type')
        .replace('nrodormitorios%5B%5D', 'bedroom')
        .toLowerCase();

      history.push(`/imoveis${params}`);
    }
  }, [location]);
  return null;
};

export default PesquisaRedirect;
