import React from 'react';

function SellHome({ history }) {
  return (
    <div
      className="sell-home-area rld-1-3-bg pd-top-100 pd-bottom-100"
      style={{ backgroundImage: 'url(assets/img/bg/6.png)' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6  mb-4 mb-lg-0 align-self-center">
            <div className="shape-image-list-wrap">
              <img
                className="shadow-img"
                src={history.image}
                alt="imagem ilustrativa"
              />
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1 align-self-center">
            <div className="section-title mb-0">
              <h2
                className="title inner-title"
                style={{ color: 'var(--main-color-one)' }}
              >
                {history.title}
              </h2>
              <p>{history.content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellHome;
