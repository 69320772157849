import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import env from './../../../.env.json';
import { Api } from '../../../Api';

import { FaArrowRight } from 'react-icons/fa';

import './FeaturedProperties.scss';
import ImageWithFallback from '../../global-components/ImageWithFallback';
import slugify from 'slugify';

function Featured() {
  const [recentlyProperties, setRecentlyProperties] = useState(null);
  const imagesUrlPrefix = env.imagesUrlPrefix;

  function getAndSetRecentlyProperties() {
    Api.getRecentlyProperties('?limit=7').then((data) => {
      setRecentlyProperties(data.properties);
    });
  }

  useEffect(() => {
    getAndSetRecentlyProperties();
  }, []);

  const slugFeatured =
    recentlyProperties &&
    slugify(recentlyProperties[0].title, {
      lower: true,
      strip: true,
      remove: '.',
    });

  const formatSubtype = (subtype) => {
    if (subtype === 'apartamento') return 'Apartamento';
    if (subtype === 'galpao/deposito/armazem') return 'Galpão/Depósito/Armazém';
    if (subtype === 'terreno em condominio') return 'Terreno em condomínio';
    if (subtype === 'casa em condominio') return 'Casa em condomínio';

    return subtype;
  };

  return (
    <>
      {recentlyProperties && (
        <div className="featured-area">
          <div className="container">
            <div className="section-title text-center">
              <h2 style={{ color: 'var(--main-color-one)' }} className="title">
                Imóveis mais recentes
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <Link
                  to={`/imoveis/${recentlyProperties[0].id}/${slugFeatured}`}
                >
                  <div className="single-leading-feature">
                    <div className="slf-overlay" />
                    <div className="thumb image-thumb--main">
                      <ImageWithFallback
                        src={
                          imagesUrlPrefix +
                          (recentlyProperties[0]?.cover ||
                            recentlyProperties[0]?.images[0])
                        }
                        alt={recentlyProperties[0].subtype}
                      />
                    </div>
                    <div className="details">
                      <span
                        className="title readeal-top property-title"
                        style={{ color: 'white' }}
                      >
                        {recentlyProperties[0].title}
                        {recentlyProperties[0].condominium_title &&
                        !Boolean(
                          recentlyProperties[0].control.condominium_title
                        )
                          ? ` - ${recentlyProperties[0].condominium_title}`
                          : ''}
                      </span>

                      <div className="row infos">
                        <div className="infos-head">
                          <h5 className="image-price">
                            {Boolean(recentlyProperties[0].control.price)
                              ? 'Valor a consultar'
                              : Number(
                                  recentlyProperties[0].price
                                ).toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                          </h5>

                          <Link
                            to={`/imoveis/${recentlyProperties[0].id}/${slugFeatured}`}
                          >
                            Ver mais <FaArrowRight className="icon-arrow" />
                          </Link>
                        </div>

                        <div className="infos-description">
                          <strong>
                            {formatSubtype(recentlyProperties[0].subtype)}
                            {!Boolean(recentlyProperties[0].control.bedroom) &&
                              ` /${recentlyProperties[0].bedroom} dorm`}
                          </strong>
                          <span>Cód: {recentlyProperties[0].code}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              {recentlyProperties.map((property, i) => {
                const control = property?.control;
                const slug = slugify(property.title, {
                  lower: true,
                  strip: true,
                  remove: '.',
                });
                let image =
                  imagesUrlPrefix + (property?.cover || property?.images[0]);

                return (
                  i !== 0 && (
                    <div
                      key={i}
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-6  mb-15"
                    >
                      <Link to={`/imoveis/${property.id}/${slug}`}>
                        <div className="single-feature">
                          <div className="thumb image-thumb">
                            <ImageWithFallback
                              src={image}
                              alt={property.subtype}
                            />
                          </div>
                          <div className="details image-details-feature-properties">
                            <span className="title property-title--little readeal-top">
                              {property.title}
                              {property.condominium_title &&
                              !Boolean(property.control.condominium_title)
                                ? ` - ${property.condominium_title}`
                                : ''}
                            </span>

                            <h6 className="price--little">
                              {Boolean(control.price)
                                ? 'Valor a consultar'
                                : Number(property.price).toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }
                                  )}
                            </h6>
                            <div id="line" />
                            <div className="row infos--little">
                              <strong>
                                {formatSubtype(property.subtype)}
                                {!Boolean(control.bedroom) &&
                                  ` / ${property.bedroom} dorm`}
                              </strong>
                              <span>Cód: {property.code}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Featured;
