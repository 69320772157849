import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ImageGallery from "react-image-gallery";
import noImage from "../../../assets/images/no-image.png";

import "react-image-gallery/styles/scss/image-gallery.scss";

import "./ImageCarousel.scss";

function ImageCarousel({ slideImages }) {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setSlides(
      slideImages.map((i) => ({
        original: i.img,
        thumbnail: i.img,
        originalClass: "gallery-image",
        thumbnailClass: "gallery-thumbnail",
      }))
    );
  }, [slideImages]);

  return (
    <ImageGallery
      showPlayButton={false}
      showFullscreenButton={false}
      items={slides}
      onErrorImageURL={noImage}
    />
  );
}

export default ImageCarousel;
