import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import BankIcon from 'react-br-bank-icons';
import './BankCards.scss';
import { Api } from '../../../Api';

const settings = {
  className: 'slider',
  variableWidth: false,
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 1000,
  slidesToScroll: 1,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const banksIcons = {
  Banrisul: 41,
  Caixa: 104,
  'Banco do Brasil': 1,
  Bradesco: 237,
  Santander: 33,
  Itaú: 341,
};

const BankCards = () => {
  const [isMobile, setIsMobile] = useState(false);

  function checkScreenWidth() {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }

  const [banks, setBanks] = useState([]);

  useEffect(() => {
    Api.getUsefulLinks().then(({ financialInstitutions }) =>
      setBanks(financialInstitutions)
    );
  }, []);

  useEffect(() => {
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
  }, []);

  return (
    <div className="service-area service-area-about pb-xl-5 pd-0">
      <h3
        className="text-center main-color"
        style={{ marginBottom: '3.125rem', fontSize: '2.375rem' }}
      >
        Saiba o que podemos fazer por você
      </h3>

      {isMobile ? (
        <Slider {...settings}>
          {banks?.map((item, i) => (
            <div key={i} className="item">
              <div className="single-intro text-center my-own-card bank-item mb-3">
                <div className="thumb bank-thumb">
                  <BankIcon color="white" bankId={banksIcons[item.name]} />
                </div>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="details link"
                >
                  <h5
                    style={{ color: 'var(--main-color-one)' }}
                    href={item.link}
                    className="title"
                  >
                    {item.name}
                  </h5>
                </a>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="desktop-grid">
          {banks?.map((item, i) => (
            <div key={i} className="item">
              <div className="single-intro text-center my-own-card bank-item mb-3">
                <div className="thumb bank-thumb">
                  <BankIcon color="white" bankId={banksIcons[item.name]} />
                </div>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="details link"
                >
                  <h5
                    style={{ color: 'var(--main-color-one)' }}
                    href={item.link}
                    className="title"
                  >
                    {item.name}
                  </h5>
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BankCards;
