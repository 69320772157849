import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import slugify from 'slugify';

import { FavoriteHeart } from './../FavoriteHeart/FavoriteHeart.jsx';
import { FilterModal } from './../FilterModal/FilterModal.jsx';
import { ReactComponent as FilterIcon } from './../../../assets/icons/filter-icon.svg';
import { ReactComponent as LoadingIcon } from './../../../assets/icons/loading-icon.svg';

import WhatsappStation from '../../global-components/WhatsappStation/WhatsappStation.jsx';

import { FaChevronDown, FaArrowRight } from 'react-icons/fa';

import { Api } from '../../../Api';

import env from './../../../.env.json';

import './SearchGridSection.scss';

import InputDoubleSlider from '../InputDoubleSlider/InputDoubleSlider.jsx';
import Pagination from '../../global-components/Pagination/Pagination.jsx';
import {
  useFavoriteProperties,
  useMinMaxValues,
  useSimilarProperties,
} from '../../../context/properties.jsx';
import useComponentVisible from '../../../hooks/useComponentVisible.js';
import ImageWithFallback from '../../global-components/ImageWithFallback/index.js';
import { propertyTypes } from '../../../data/propertyTypes';

import {
  MultiSelect,
  StyledOption,
  BpCheckedIcon,
  BpIcon,
} from '../../global-components/MultiSelect/MultiSelect';
import { Checkbox } from '@mui/material';

const dorms = {
  1: '1 Dormitório',
  2: '2 Dormitórios',
  3: '3 Dormitórios',
  4: '4 Dormitórios',
  5: '5 Dormitórios',
};

const bedroomsAndGarageOptions = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
];

const sortOptions = [
  { value: 'relevant', label: 'Mais Relevantes' },
  { value: 'high_price', label: 'Maior Preço' },
  { value: 'low_price', label: 'Menor Preço' },
];

function SearchGridSection() {
  const { search } = useLocation();
  const history = useHistory();

  const limit = 9;
  const imagesUrlPrefix = env.imagesUrlPrefix;

  const { favoriteProperties, setFavoriteProperties } = useFavoriteProperties();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const { minMaxPropertyValues, minMaxMajorArea, minMaxMinorArea, maxPrice } =
    useMinMaxValues();

  const { setSimilarProperties, setSimilarPropertiesUrl } =
    useSimilarProperties();

  const codeParam = new URLSearchParams(search).get('code');
  const stageParam = new URLSearchParams(search).get('stage');
  const categoryParam = new URLSearchParams(search).get('category');
  const typeParam = new URLSearchParams(search).get('type');
  const bedroomParam = new URLSearchParams(search).get('bedroom');
  const districtParam = new URLSearchParams(search).get('district');
  const minPriceParam = new URLSearchParams(search).get('minPrice');
  const maxPriceParam = new URLSearchParams(search).get('maxPrice');
  const filtersParam = new URLSearchParams(search).get('filters');

  const [offset, setOffset] = useState(0);
  const [properties, setProperties] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orderValue, setOrderValue] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [queryValues, setQueryValues] = useState('');
  const [advancedFilterValues, setAdvancedFilterValues] = useState(
    filtersParam?.split(',').map((x) => +x) || []
  );

  // PropertyValue Slider

  const [propertyValue, setPropertyValue] = useState([
    Number(minPriceParam) || minMaxPropertyValues[0],
    Number(maxPriceParam) || minMaxPropertyValues[1],
  ]);

  const propertySliderStep = 100000;
  const propertySliderName = 'propertyValue';

  // PropertyArea Slider

  const [areaValue, setAreaValue] = useState(minMaxMajorArea);
  const [minMaxAreaValues, setMinMaxAreaValues] = useState(minMaxMajorArea);

  const areaSliderStep = 10;
  const areaSliderName = 'propertyArea';

  // Formik
  const initialValues = {
    code: codeParam || '',
    stage: stageParam || '',
    category: categoryParam || '',
    type: typeParam?.split(',') || '',
    district: districtParam?.split(',').map((x) => +x) || '',
    propertyValue: [
      Number(minPriceParam) || minMaxPropertyValues[0],
      Number(maxPriceParam) || minMaxPropertyValues[1],
    ],
    bedroom: bedroomParam?.split(',') || '',
    garage: '',
    propertyArea: minMaxMajorArea,
    characteristics: filtersParam?.split(',').map((x) => +x) || [],
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });

  function getDistricts() {
    Api.getAvailableDistricts().then((data) => {
      let districtsData = data.map((district) => ({
        id: district.id,
        name: district.title,
      }));

      setDistricts(districtsData);
    });
  }

  function handleCategorySelect(e) {
    handleChange(e);
    setFieldValue('type', '');
  }

  const handleItemMenu = (o) => {
    if (o) {
      setOrderValue(o);
    }
    if (!o) {
      setOrderValue();
    }

    !isComponentVisible
      ? setIsComponentVisible(true)
      : setIsComponentVisible(false);
  };

  const handleOrderMenu = () => {
    !isComponentVisible
      ? setIsComponentVisible(true)
      : setIsComponentVisible(false);
  };

  function toggleFavorite(item, e) {
    e.preventDefault();
    const exist = favoriteProperties.find((x) => x.id === item.id);

    const propertyInformations = {
      id: item.id,
      title: item.title,
      price: item.price,
      code: item.code,
      bedroom: item.bedroom,
      garage_vacancy: item.garage_vacancy,
      bathroom: item.bathroom,
      image: imagesUrlPrefix + (item?.cover || item?.images[0]),
      control: item.control,
      description: item.description,
      condominium_title: item.condominium_title,
    };

    if (exist) {
      setFavoriteProperties((oldState) =>
        oldState.filter((x) => x.id !== item.id)
      );
    } else {
      setFavoriteProperties((oldState) => [...oldState, propertyInformations]);
    }
  }

  function handleQueryValues(values) {
    if (queryValues !== '') {
      setQueryValues('');
    }
    let query = Object.entries(values)
      .map(([k, v]) => {
        if (v === '' || v === null) return null;
        if (k === 'characteristics') {
          const chars = v
            .map((char) => {
              return `characteristics=${char}`;
            })
            .join('&');
          return chars;
        }
        if (k === 'garage') {
          return `garage_vacancy=${v}`;
        }
        if (k === 'propertyValue') {
          return `minPrice=${v[0]}&maxPrice=${v[1]}`;
        }

        if (
          values.type === 'terreno' ||
          values.type === 'terreno em condomínio' ||
          values.type === ''
        ) {
          if (k === 'propertyArea') {
            return `minMajorArea=${v[0]}&maxMajorArea=${v[1]}`;
          } else if (k === 'propertyArea') {
            return `minMinorArea=${v[0]}&maxMinorArea=${v[1]}`;
          }
        }

        if (k) return `${k}=${v}`;
      })
      .filter(Boolean)
      .join('&');

    setQueryValues(query);
  }

  function selectedCategory(value) {
    if (value.value === values.category) {
      return value.subTypes;
    }
  }

  useEffect(() => {
    setFieldValue('characteristics', advancedFilterValues);
  }, [advancedFilterValues]);

  useEffect(() => {
    if (!isSubmitting) {
      if (values.type) {
        getDistricts(values.category, values.type);
      } else if (values.category && !values.type) {
        getDistricts(values.category);
      } else if (!values.category && !values.type) {
        getDistricts();
      }
    }
  }, [values.category, values.type, isSubmitting]);

  useEffect(() => {
    if (
      values.type === 'terreno' ||
      values.type === 'terreno em condomínio' ||
      values.type === ''
    ) {
      setAreaValue(minMaxMajorArea);
      setMinMaxAreaValues(minMaxMajorArea);
    } else {
      setAreaValue(minMaxMinorArea);
      setMinMaxAreaValues(minMaxMinorArea);
    }
  }, [minMaxMajorArea, values.type]);

  useEffect(() => {
    setPropertyValue([minMaxPropertyValues[0], minMaxPropertyValues[1]]);
  }, [minMaxPropertyValues]);

  useEffect(() => {
    setOffset(0);

    const newValues = {
      ...values,
      propertyValue: [
        values.propertyValue[0],
        values.propertyValue[1] === 5000000
          ? maxPrice
          : values.propertyValue[1],
      ],
    };
    handleQueryValues(newValues);
  }, [values]);

  useEffect(() => {
    let order = orderValue?.value;

    if (queryValues) {
      if (order) {
        Api.searchProperties(queryValues, limit, offset, order).then(
          (properties) => {
            setProperties(properties);
          }
        );
      } else if (!order) {
        Api.searchProperties(queryValues, limit, offset).then((properties) => {
          setProperties(properties);
        });
      }
    }
  }, [queryValues, offset, orderValue]);

  useEffect(() => {
    if (properties && properties.properties) {
      setSimilarProperties(properties?.properties);
      setSimilarPropertiesUrl(search);
    }
  }, [properties]);

  useEffect(() => {
    if (
      search === '?category=comercial' ||
      search === '?category=residencial'
    ) {
      setAdvancedFilterValues([]);
    }
  }, [search]);

  const formatSubtype = (subtype) => {
    if (subtype === 'apartamento') return 'Apartamento';
    if (subtype === 'galpao/deposito/armazem') return 'Galpão/Depósito/Armazém';
    if (subtype === 'terreno em condominio') return 'Terreno em condomínio';
    if (subtype === 'casa em condominio') return 'Casa em condomínio';

    return subtype;
  };

  return (
    <div className="search-page-wrap pd-top-70">
      <FilterModal
        history={history}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        advancedFilterValues={advancedFilterValues}
        setAdvancedFilterValues={setAdvancedFilterValues}
      />
      <div className="search-container">
        <WhatsappStation />

        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 sitebar">
              <h6 className="filter-title" style={{ marginBottom: '32.527px' }}>
                <FilterIcon className="mr-3" />
                Filtros
              </h6>
              <form
                onSubmit={handleSubmit}
                className="widget widget-sidebar-search-wrap"
              >
                <div
                  className="widget-sidebar-search"
                  style={{ width: '100%' }}
                >
                  <div className="black-title">Refinar sua busca</div>
                  <div className="widget-sidebar-item-wrap rld-single-input with-border-bottom">
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="code"
                      placeholder="Busca por código"
                      className="code"
                    />
                  </div>

                  <div className="black-title">Tipo de imóvel</div>
                  <div className="rld-single-select grid-multiple-fields">
                    <select
                      name="category"
                      className="select single-select type"
                      onChange={(e) => handleCategorySelect(e)}
                      value={values.category}
                    >
                      <option value="">Categoria</option>

                      {propertyTypes.map((type, i) => (
                        <option key={i} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                    <MultiSelect
                      disabled={!values.category}
                      className="select single-select search-grid-multiselect"
                      value={values.type}
                      onChange={(e) => setFieldValue('type', e)}
                      renderValue={(selected) => {
                        let label = selected.map((type) => type.label[1]);
                        if (selected.length === 0) {
                          return <option>Tipos</option>;
                        }
                        return label.join(', ');
                      }}
                    >
                      {propertyTypes
                        ?.filter(selectedCategory)
                        .flatMap((type) => type.subTypes)
                        .map((subType, i) => {
                          const formattedLabel = subType.label.replace(
                            /\//g,
                            ' / '
                          );

                          return (
                            <StyledOption key={i} value={subType.value}>
                              <Checkbox
                                sx={{
                                  '&:hover': {
                                    bgcolor: 'transparent',
                                  },
                                }}
                                disableRipple
                                color="default"
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                                inputProps={{
                                  'aria-label': 'Checkbox demo',
                                }}
                                checked={
                                  values?.type?.indexOf(subType.value) > -1
                                }
                              />

                              {formattedLabel}
                            </StyledOption>
                          );
                        })}
                    </MultiSelect>

                    <MultiSelect
                      className="select single-select"
                      value={values.district}
                      onChange={(e) => setFieldValue('district', e)}
                      renderValue={(selected) => {
                        let label = selected.map(
                          (district) => district.label[1]
                        );
                        if (selected.length === 0) {
                          return <option>Bairros</option>;
                        }
                        return label.join(', ');
                      }}
                    >
                      {districts?.map((district, i) => {
                        const formattedLabel = district.name.replace(
                          /\//g,
                          ' / '
                        );
                        return (
                          <StyledOption key={i} value={district.id}>
                            <Checkbox
                              sx={{
                                '&:hover': {
                                  bgcolor: 'transparent',
                                },
                              }}
                              disableRipple
                              color="default"
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                              inputProps={{
                                'aria-label': 'Checkbox demo',
                              }}
                              checked={
                                values.district.indexOf(district.id) > -1
                              }
                            />

                            {formattedLabel}
                          </StyledOption>
                        );
                      })}
                    </MultiSelect>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-price-slider-wrap rld-single-input with-border-bottom">
                    <div className="black-title">Valor do imóvel</div>

                    <InputDoubleSlider
                      minMaxValue={minMaxPropertyValues}
                      visibleValue={propertyValue}
                      setVisibleValue={setPropertyValue}
                      step={propertySliderStep}
                      setFieldValue={setFieldValue}
                      name={propertySliderName}
                      hasMaxSufix={minMaxPropertyValues[1] === 5000000}
                    />
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-select with-border-bottom row-fields">
                    <div style={{ width: '50%' }}>
                      <h6 className="black-title">Dormitórios</h6>
                      <MultiSelect
                        className="select single-select"
                        value={values.bedroom}
                        onChange={(e) => setFieldValue('bedroom', e)}
                        renderValue={(selected) => {
                          let label = selected.map(
                            (bedroom) => bedroom.label[1]
                          );
                          if (selected.length === 0) {
                            return <option>Dorms.</option>;
                          }
                          return label.join(', ');
                        }}
                      >
                        {Object.entries(dorms).map(([value, label]) => (
                          <StyledOption key={value} value={value}>
                            <Checkbox
                              sx={{
                                '&:hover': {
                                  bgcolor: 'transparent',
                                },
                              }}
                              disableRipple
                              color="default"
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                              inputProps={{
                                'aria-label': 'Checkbox demo',
                              }}
                              checked={values?.bedroom?.indexOf(value) > -1}
                            />

                            {label}
                          </StyledOption>
                        ))}
                      </MultiSelect>
                    </div>
                    <div style={{ width: '50%' }}>
                      <h6 className="black-title">Garagem</h6>
                      <select
                        name="garage"
                        onChange={handleChange}
                        className="select single-select w-100 garage"
                        value={values.garage}
                      >
                        <option value="">Vagas</option>
                        {bedroomsAndGarageOptions?.map((garage, i) => (
                          <option key={i} value={garage.value}>
                            {garage.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="widget-sidebar-item-wrap rld-price-slider-wrap rld-single-input with-border-bottom">
                    <div className="black-title">Área do imóvel</div>

                    <InputDoubleSlider
                      area
                      minMaxValue={minMaxAreaValues}
                      visibleValue={areaValue}
                      setVisibleValue={setAreaValue}
                      step={areaSliderStep}
                      name={areaSliderName}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>
                <div className="btn-wrap text-center">
                  <div
                    style={{
                      marginTop:
                        window.innerWidth > 992 || window.innerWidth < 512
                          ? '16px'
                          : '0',
                      marginRight:
                        window.innerWidth > 550 && window.innerWidth < 992
                          ? '10px'
                          : '0',
                    }}
                    className="btn btn-yellow"
                    onClick={() => setModalIsOpen(true)}
                  >
                    <span style={{ padding: '0 14px' }}>
                      <FilterIcon
                        height={15}
                        className="mr-3 white-filter-icon"
                      />
                      Mais filtros
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-9 col-lg-8 search-card-grid">
              <div className="row mb-3">
                <div className="grid-header-container">
                  <h6 className="filter-title mb-lg-0">
                    <span
                      style={{
                        color: '#000',
                        marginRight: '4px',
                        fontWeight: 400,
                      }}
                    >
                      Encontramos
                    </span>
                    {properties.count} Imóveis
                  </h6>
                  <div className="filter-menu-wrapper">
                    <button onClick={() => handleOrderMenu()}>
                      {orderValue ? orderValue.label : 'Mais Recentes'}{' '}
                      <FaChevronDown size={14} />
                    </button>
                    {isComponentVisible && (
                      <div ref={ref} className="filter-menu">
                        <ul>
                          <li onClick={() => handleItemMenu()}>
                            Mais Recentes
                          </li>
                          {sortOptions.map((o, i) => (
                            <li
                              className="sort-item"
                              key={i}
                              onClick={() => handleItemMenu(o)}
                            >
                              {o.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {!properties.properties && <LoadingIcon />}

                {properties?.properties?.map((item, i) => {
                  const control = item?.control;
                  const slug = slugify(item.title, {
                    lower: true,
                    strip: true,
                    remove: '.',
                  });
                  let image =
                    imagesUrlPrefix + (item?.cover || item?.images[0]);

                  return (
                    <div
                      key={i}
                      className="col-xl-4 col-sm-6"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Link to={`/imoveis/${item.id}/${slug}`}>
                        <div className="single-feature">
                          <div className="thumb custom-thumb">
                            <ImageWithFallback src={image} alt={item.subtype} />
                            <FavoriteHeart
                              className="favorite-icon"
                              isActive={
                                favoriteProperties.findIndex(
                                  (el) => el.id === item.id
                                ) > -1
                              }
                              handleClick={(e) => toggleFavorite(item, e)}
                            />
                          </div>

                          <div className="details image-card">
                            <h4 className="title readeal-top property-title custom-property-title">
                              {item.title}
                              {item.condominium_title &&
                              !Boolean(item.control.condominium_title)
                                ? ` - ${item.condominium_title}`
                                : ''}
                            </h4>

                            <div className="row infos custom-infos">
                              <div className="custom-infos-wrapper">
                                <h5 className="price custom-price">
                                  {Boolean(control.price)
                                    ? 'Valor a consultar'
                                    : Number(item.price).toLocaleString(
                                        'pt-br',
                                        {
                                          style: 'currency',
                                          currency: 'BRL',
                                        }
                                      )}
                                </h5>
                                <div className="code-wrapper">
                                  <strong>
                                    {formatSubtype(item.subtype)}

                                    {!Boolean(control.bedroom) &&
                                      ` / ${item.bedroom} dorm`}
                                  </strong>
                                  <span>Cód: {item.code}</span>
                                </div>
                              </div>

                              <Link
                                to={`/imoveis/${item.id}/${slug}`}
                                className="see-details-link"
                              >
                                Ver detalhes
                                <FaArrowRight className="arrow-icon" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {properties.count && (
            <Pagination
              pageCount={Math.ceil(properties.count / limit)}
              currentPage={offset}
              setCurrentPage={setOffset}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchGridSection;
