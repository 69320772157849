import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Home from './pages/home';
import AvailableProperty from './pages/available-property';
import RecentProperties from './pages/recent-properties';
import PropertyDetails from './pages/PropertyDetails';
import About from './pages/About/About.jsx';
import Pricing from './pages/pricing';
import UsefulLinks from './pages/useful-links';

import Registraion from './pages/registration';
import Error from './pages/error';
import Faq from './pages/faq';
import Blog from './pages/Blog';
import Post from './pages/post';
import Contact from './pages/Contact/Contact.jsx';
import SearchMap from './pages/search-map';
import SearchGrid from './pages/SearchGrid';
import SearchList from './pages/search-list';
import AddNew from './pages/add-property';
import PropertiesByNeighborhood from './components/section-components/PropertiesByNeighborhood/PropertiesByNeighborhood';
import FavoriteProperties from './pages/FavoriteProperties/FavoriteProperties.jsx';
import SellProperty from './pages/SellProperty/SellProperty';
import EvaluationPage from './pages/EvaluationPage/EvaluationPage';
import ScrollToTop from './components/global-components/ScrollToTop/index';
import FinancingSimulation from './pages/FinancingSimulation/FinancingSimulation';
import PesquisaRedirect from './pages/PesquisaRedirect';

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/imoveis/:id/:slug" component={PropertyDetails} />
        <Route path="/imoveis" component={SearchGrid} />
        <Route path="/fale-conosco" component={Contact} />
        <Route path="/blog/post/:id/:slug" component={Post} />
        <Route path="/blog" component={Blog} />
        <Route path="/favoritos" component={FavoriteProperties} />
        <Route path="/venda-seu-imovel" component={SellProperty} />
        <Route path="/sobre-nos" component={About} />
        <Route path="/duvidas-frequentes" component={Faq} />
        <Route path="/solicitar-avaliacao" component={EvaluationPage} />
        <Route path="/simular-financiamento" component={FinancingSimulation} />
        <Route path="/links-uteis" component={UsefulLinks} />

        <Route path="/pesquisa" component={PesquisaRedirect} />

        {/* wrong url redirects to home */}
        <Route component={() => <Redirect to={{ pathname: '/' }} />} />
      </Switch>
    </BrowserRouter>
  );
};

export { Routes };
