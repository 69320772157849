import React, { useState, useEffect } from 'react';

import Header from '../../components/global-components/Header/Header';
import Navbar from '../../components/global-components/Navbar/Navbar';
import PageInfos from '../../components/global-components/PageInfos/PageInfos';
import Professional from '../../components/section-components/professional';
import SellHome from '../../components/section-components/sellhome';
import Team from '../../components/section-components/Team/Team.jsx';
import Testimonials from '../../components/section-components/Testimonials/Testimonials';
import OurSocialMedias from '../../components/section-components/our-social-medias';
import Footer from '../../components/global-components/Footer/Footer.jsx';
import Slideshow from '../../components/section-components/Slideshow/Slideshow.jsx';
import ServiceOne from '../../components/section-components/ServiceOne/ServiceOne';

import WhatsappStation from '../../components/global-components/WhatsappStation/WhatsappStation.jsx';

import env from '../../.env.json';

import './About.scss';
import { Api } from '../../Api';

const About = () => {
  const [carouselImages, setCarouselImages] = useState(null);
  const [histories, setHistories] = useState(null);

  const imagePrefix = env.generalImagesUrlPrefix;

  const items = [
    {
      title: 'Regularização de imóveis',
      content:
        'Assessoramento para regularização de imóveis – Já escutou que "só quem registra é dono"? E é verdade! Se você possui um imóvel e não sabe como registrar, entre em contato conosco.',
      icon: 'assets/img/icons/13.png',
    },
    {
      title: 'Intermediação Imobiliária',
      content:
        'Oferecemos o serviço de intermediação na compra e venda de imóveis novos e usados na cidade de Pelotas, incluindo empreendimentos de Alto Padrão',
      icon: 'assets/img/icons/21.png',
    },
    {
      title: 'Acompanhamento de Financiamento',
      content:
        'Um dos bens mais preciosos na atualidade é o tempo. O processo de financiamento poderá demandar algumas horas do seu dia, mas a Moysés Imóveis ajuda você! Acompanhamos e assessoramos financiamentos por todas as instituições bancárias.',
      icon: 'assets/img/icons/20.png',
    },
    {
      title: 'Avaliação de Imóveis',
      content:
        'Urbanos e rurais, realizando vistoria junto ao imóvel e entendendo a necessidade do solicitante para garantir a transparência e adequada precificação do imóvel. Corretores inscritos no Cadastro Nacional de Avaliadores de Imóveis.',
      icon: 'assets/img/icons/clipboard-data.svg',
    },
  ];

  function getCarouselImages() {
    Api.getAboutUsCarousel().then((data) => {
      let images = data?.map((obj) => `${imagePrefix}${obj.imagem}`);
      setCarouselImages(images);
    });
  }

  function getTimeline() {
    Api.getAboutUsTimeline().then((data) => {
      let items = data
        ?.sort((a, b) => {
          return a.year - b.year;
        })
        .map((obj) => ({
          title: obj.year,
          image: `${imagePrefix}/sobre/${obj.image}`,
          content: obj.text,
        }));
      setHistories(items);
    });
  }

  useEffect(() => {
    getCarouselImages();
    getTimeline();
  }, []);

  return (
    <div>
      <Header />
      <Navbar />
      <PageInfos currentPageName="Sobre nós" />
      <WhatsappStation />

      <div className="pd-top-30 md-top-50 pd-bottom-70 company-container">
        <div className="container">
          <h3 className="text-center main-color my-about-title">
            UMA EMPRESA COM FOCO NAS PESSOAS
          </h3>
          <div className="my-phrases-container">
            <p>
              Com foco exclusivo na intermediação da compra e venda de imóveis
              novos e usados, somos uma empresa familiar que atua no mercado de
              Pelotas há mais de 20 anos. Outras atividades deste ramo agregam
              valor à nossa empresa, como a avaliação imobiliária, o
              assessoramento à regularização de imóveis e o acompanhamento de
              financiamentos. Aqui na Moysés Imóveis, você encontrará um
              ambiente familiar e uma equipe apaixonada e comprometida pelo que
              faz, sempre buscando melhorias e visando a um atendimento cada vez
              melhor.
            </p>
            <p>
              Dispomos de tempo para ajudar os compradores e vendedores e
              oferecemos segurança no processo de compra, venda, avaliação e
              assessoramento. Nossos corretores têm legitimidade na área de
              atuação, sendo todos credenciados junto ao Conselho Regional de
              Corretores de Imóveis. O que queremos é ajudar você, participando
              ativamente de um dos momentos mais importantes de sua vida: a
              aquisição ou a venda do seu imóvel!
            </p>
          </div>
        </div>
      </div>
      {carouselImages && (
        <div className="pd-bottom-70 slide-wrapper">
          <Slideshow slideImages={carouselImages} />
        </div>
      )}
      <ServiceOne items={items} />
      <Team />

      <div className="histories-container">
        {histories?.map((history, index) => {
          return (index + 1) % 2 === 0 ? (
            <SellHome key={index} history={history} />
          ) : (
            <Professional key={index} history={history} />
          );
        })}
      </div>
      <Testimonials />
      <OurSocialMedias />
      <Footer />
    </div>
  );
};

export default About;
