import React, { useState, useEffect } from 'react';

import { Api } from '../../../Api';
import env from '../../../.env.json';
import './Team.scss';

const Team = () => {
  const imagePrefix = env.contributorUrlPrefix;
  const [contributors, setContributors] = useState(null);

  useEffect(() => {
    Api.getContributors().then((c) => setContributors(c));
  }, []);

  return (
    <div className="team-area bg-gray pd-top-30 md-top-50 pd-bottom-70">
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title" style={{ color: 'var(--main-color-one)' }}>
            Uma equipe apaixonada pelo que faz
          </h2>
        </div>
        <div className="row">
          {contributors?.map(
            ({ id, image, image_hover, name, role, text }, i) => {
              return (
                <div key={id} className="col-lg-4 col-sm-6">
                  <div className="single-team">
                    <div>
                      <div alt="team" className="image-wrapper">
                        <img src={imagePrefix + image} className="image" />
                        <img
                          src={imagePrefix + image_hover}
                          className="image-hover"
                        />
                      </div>
                    </div>
                    <div className="my-team-details team-details">
                      <h4>{name}</h4>
                      <span>{role}</span>
                      <p>{text}</p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;
