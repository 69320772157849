import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Earphone } from './../../../assets/icons/earphone-icon.svg';
import { ReactComponent as HeartIcon } from './../../../assets/icons/heart-icon.svg';
import { ReactComponent as WhatsappRed } from './../../../assets/icons/whatsapp-red-icon.svg';

import './Header.scss';

function Header() {
  return (
    <div className="header-container ">
      <div className="header-infos">
        <div className="row contact">
          <span className="row header-infos__contacts">
            <Earphone color="var(--heading-color)" />
            <div className="col-sm">
              <p>Fale conosco</p>
              <a href="tel:53 3028-5706">
                <strong>(53) 3028-5706</strong>
              </a>
            </div>
          </span>

          <a
            className="row header-infos__contacts"
            href="https://api.whatsapp.com/send?phone=5553981600126"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsappRed color="var(--heading-color)" />
            <div className="col-sm">
              <strong>(53) 98160-0126</strong>
            </div>
          </a>
        </div>
        <div className="header-infos__button-container">
          <Link to="/venda-seu-imovel" className="btn btn-yellow">
            Anuncie seu imóvel
          </Link>
          <Link to="/favoritos">
            <HeartIcon color="var(--heading-color)" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
