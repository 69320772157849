import React, { useState, useEffect } from 'react';
import { Formik, FastField } from 'formik';
import InputMask from 'react-input-mask';
import { TextField as MuiTextField } from 'formik-mui';

import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';
import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import ImageUploading from 'react-images-uploading';

import Header from '../../components/global-components/Header/Header';
import Navbar from '../../components/global-components/Navbar/Navbar';
import PageInfos from '../../components/global-components/PageInfos/PageInfos.jsx';
import OurSocialMedias from '../../components/section-components/our-social-medias';
import Footer from '../../components/global-components/Footer/Footer.jsx';
import WhatsappStation from '../../components/global-components/WhatsappStation/WhatsappStation.jsx';
import ServiceTwo from '../../components/section-components/ServiceTwo/ServiceTwo';
import { ReactComponent as LoadingIcon } from './../../assets/icons/loading-icon.svg';

import { FaTrashAlt } from 'react-icons/fa';

import { Api } from '../../Api';

import './SellProperty.scss';
import NumberFormat from 'react-number-format';
import { Helmet } from 'react-helmet';

const bedroomsAndGarageOptions = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
];

const typeOptions = [
  {
    value: 'Comercial',
    label: 'Comercial',
  },
  {
    value: 'Residencial',
    label: 'Residencial',
  },
  {
    value: 'Rural',
    label: 'Rural',
  },
];

const items = [
  {
    image: 'assets/img/icons/13.png',
    content:
      'Através de vídeos e fotos caprichadas, divulgação em mídias sociais e portais especializados de imóveis proporcionaram maior visibilidade e oportunidades para nossos clientes.',
    icon: 'assets/img/icons/19.png',
  },
  {
    image: 'assets/img/icons/12.png',
    content:
      'O segredo do nosso sucesso está em nosso atendimento personalizado e agilidade na negociação, tudo para garantir um processo de venda sem complicações e surpresas!',
    icon: 'assets/img/icons/20.png',
  },
  {
    image: 'assets/img/icons/11.png',
    content:
      'Uma equipe qualificada e com experiência no mercado garantem toda segurança necessária para efetuar negócios com sucesso e transparência para você.',
    icon: 'assets/img/icons/21.png',
  },
];

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      prefix="R$ "
    />
  );
});

const SellProperty = () => {
  const logo = 'https://cms.moysesimoveis.com.br/upload/logo-98239781.png';
  const initialValues = {
    name: '',
    phone: '',
    email: '',
    propertyType: '',
    district: '',
    address: '',
    propertyName: '',
    bedroom: '',
    suites: '',
    garage: '',
    condominiumPrice: '',
    pretendedPrice: '',
    evaluation: null,
    characteristics: '',
    images: [],
    agreed: true,
  };

  const [districts, setDistricts] = useState([]);
  const [formPage, setFormPage] = useState(1);
  const [status, setStatus] = useState();
  const [isSending, setIsSending] = useState(false);

  async function sendProperty(values) {
    Api.sendPropertyForm(values, setStatus, setIsSending);
  }

  function getDistricts() {
    Api.getDistricts().then((data) => {
      setDistricts(data);
    });
  }

  function whichFormShouldRender(
    values,
    touched,
    handleChange,
    setFieldValue,
    handleBlur,
    errors
  ) {
    switch (formPage) {
      case 1:
        return (
          <div className="contact">
            <div>
              <FastField
                component={TextField}
                className="test-input"
                variant="outlined"
                name="name"
                label="Nome*"
              />
            </div>
            <div className="mt-18">
              <InputMask
                mask="(99) 99999-9999"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {(inputProps) => (
                  <FastField
                    component={TextField}
                    className="test-input"
                    variant="outlined"
                    name="phone"
                    label="Seu Telefone*"
                    inputProps={{ ...inputProps }}
                  />
                )}
              </InputMask>
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                type="email"
                variant="outlined"
                name="email"
                label="Seu Email*"
              />
            </div>
            <div>
              <FormControlLabel
                classes="checkbox-label"
                control={
                  <Checkbox
                    name="agreed"
                    checked={values.agreed}
                    onChange={handleChange}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: 'var(--main-color-one)',
                      },
                    }}
                  />
                }
                label="Dados utilizados exclusivamente pela Moysés Imóveis para
              responder seu contato e envio de informações do mercado
              imobiliário."
              />
              {errors.agreed && <p className="input-error">{errors.agreed}</p>}
            </div>

            <button
              type="button"
              className="btn btn-yellow mt-18 sell-property-form__contact__button"
              onClick={() =>
                values.name &&
                values.phone &&
                values.email &&
                setFormPage((page) => page + 1)
              }
            >
              Continuar
            </button>
            <p>
              {touched.name &&
                !values.name &&
                touched.phone &&
                !values.phone &&
                touched.email &&
                !values.email &&
                touched.agreed &&
                !values.agreed &&
                'Preencha os campos obrigatórios'}
            </p>
          </div>
        );

      case 2:
        return (
          <div className="contact">
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                select
                variant="outlined"
                name="propertyType"
                label="Tipo*"
              >
                {typeOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FastField>
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                select
                variant="outlined"
                name="district"
                label="Bairro"
              >
                {districts?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </FastField>
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                type="address"
                variant="outlined"
                name="address"
                label="Endereço"
              />
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                variant="outlined"
                name="propertyName"
                label="Nome do condomínio"
              />
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                select
                variant="outlined"
                name="bedroom"
                label="Dormitórios"
              >
                {bedroomsAndGarageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FastField>
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                select
                variant="outlined"
                name="suites"
                label="Suítes"
              >
                {bedroomsAndGarageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FastField>
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                select
                variant="outlined"
                name="garage"
                label="Vagas de garagem"
              >
                {bedroomsAndGarageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FastField>
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                variant="outlined"
                name="condominiumPrice"
                label="Valor do condomínio"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                variant="outlined"
                name="pretendedPrice"
                label="Valor do imóvel"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>

            <div className="mt-18">
              <FastField
                component={TextField}
                className="test-input"
                multiline
                type="number"
                variant="outlined"
                name="characteristics"
                label="Outras características"
              />
            </div>

            <button
              type="button"
              className="btn btn-yellow mt-18 sell-property-form__contact__button"
              onClick={() => setFormPage((page) => page + 1)}
            >
              Continuar
            </button>
          </div>
        );

      case 3:
        return (
          <>
            {status === undefined ? (
              isSending ? (
                <div className="loading-container">
                  <div className="loading">
                    <LoadingIcon />
                  </div>
                  <span>Enviando...</span>
                </div>
              ) : (
                <div className="contact">
                  <ImageUploading
                    multiple
                    value={values.images}
                    name="images"
                    onChange={(e) => setFieldValue('images', e)}
                    maxNumber={15}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          type="button"
                          style={
                            isDragging
                              ? { color: 'var(--main-color-one)' }
                              : undefined
                          }
                          onClick={onImageUpload}
                          {...dragProps}
                          className="image-input"
                        >
                          Clique aqui ou arraste as imagens para cá (máx. 10
                          fotos)
                        </button>

                        <div className="images-grid">
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image['data_url']} />

                              <FaTrashAlt
                                className="image-item__btn-wrapper"
                                onClick={() => onImageRemove(index)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>

                  <button
                    type="submit"
                    className="btn btn-yellow mt-18 sell-property-form__contact__button"
                  >
                    Enviar
                  </button>
                </div>
              )
            ) : status >= 200 && status < 300 ? (
              <div className="status-wrapper">
                <CheckCircleIcon />
                <p>Formulário enviado!</p>
              </div>
            ) : (
              <div className="status-wrapper">
                <ErrorIcon />
                <p>Ocorreu algum erro!</p>
                <p>Tente novamente mais tarde.</p>
              </div>
            )}
          </>
        );

      default:
        return null;
    }
  }

  useEffect(() => {
    getDistricts();
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          property="og:title"
          content="Moysés Imóveis | Anuncie seu imóvel"
        />
        <meta property="og:image" content={logo} />
        <meta
          property="og:description"
          content="Comprometimento, informação, lisura, segurança, seriedade e tempo são valores que pautam o nosso dia a dia na Moysés Imóveis."
        />
        <meta
          property="og:url"
          content="https://www.moysesimoveis.com.br/venda-seu-imovel"
        />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content="Moysés Imóveis" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={logo} />
      </Helmet>
      <Header />
      <Navbar />
      <PageInfos currentPageName="Venda seu imóvel" />
      <div className="property-details-area">
        <div>
          <div className="container">
            <WhatsappStation />

            <div className="row sell-property-form pd-top-50">
              <div className="my-main-container">
                <div className="contact-description-container">
                  <h2 className="contact-title">
                    CONTE COM NOSSA EQUIPE PARA GERAR OPORTUNIDADES DE NEGÓCIO
                    PARA VOCÊ!
                  </h2>
                  <p className="contact-phrase margin-bottom">Como funciona?</p>
                  <p className="contact-phrase">
                    Na Moysés Imóveis, seu imóvel ganha mais visibilidade!
                  </p>
                  <p className="contact-phrase">
                    Para vender seu imóvel com a gente é bem simples: basta
                    preencher o formulário nesta página e um de nossos
                    corretores entrará em contato para agendar um dia e hora
                    para conhecer seu imóvel.
                  </p>
                </div>
                <div className="form-wrapper">
                  <ServiceTwo items={items} />

                  <Formik
                    initialValues={initialValues}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = 'Campo obrigatório';
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = 'E-mail inválido';
                      }

                      if (!values.name) {
                        errors.name = 'Campo obrigatório';
                      }

                      if (!values.phone) {
                        errors.phone = 'Campo obrigatório';
                      } else if (
                        !/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/g.test(values.phone)
                      ) {
                        errors.phone = 'Número inválido';
                      }

                      if (!values.agreed) {
                        errors.agreed = 'Campo obrigatório';
                      }

                      return errors;
                    }}
                    onSubmit={(values, { resetForm }) => {
                      let bodyFormData = new FormData();
                      bodyFormData.append('name', values.name);

                      bodyFormData.append(
                        'subject',
                        `Anunciar Imóvel — ${values.name}`
                      );
                      bodyFormData.append('email', values.email);
                      bodyFormData.append('phone', values.phone);
                      bodyFormData.append('type', values.propertyType);
                      bodyFormData.append('district', values.district);
                      bodyFormData.append('address', values.address);
                      bodyFormData.append('condominium', values.propertyName);
                      bodyFormData.append('bedrooms', values.bedroom);
                      bodyFormData.append('suites', values.suites);
                      bodyFormData.append('vacancies', values.garage);
                      bodyFormData.append(
                        'condominium_price',
                        values.condominiumPrice
                      );
                      bodyFormData.append(
                        'intended_value',
                        values.pretendedPrice
                      );
                      bodyFormData.append(
                        'observations',
                        values.characteristics
                      );
                      if (values.images) {
                        values.images.map((i) => {
                          bodyFormData.append('photos', i.file);
                        });
                      }
                      sendProperty(bodyFormData);
                      resetForm();
                    }}
                  >
                    {({
                      values,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      errors,
                      /* and other goodies */
                    }) => {
                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="sell-property-form__contact"
                        >
                          {whichFormShouldRender(
                            values,
                            touched,
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            errors
                          )}
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="row my-contact-info">
                  <div className="col-xl-3 col-sm-6 my-contact-info__data">
                    <div className="single-contact-info">
                      <p>
                        <i className="fa fa-phone" />
                        <strong>Plantão:</strong>
                      </p>
                      <h5 style={{ color: '#999999' }}>(53) 98116-1121</h5>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6 my-contact-info__data">
                    <div className="single-contact-info">
                      <p>
                        <i className="fa fa-fax" />
                        <strong>Telefone:</strong>
                      </p>
                      <h5 style={{ color: '#999999' }}>(53) 3028-5706</h5>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6 my-contact-info__data">
                    <div className="single-contact-info">
                      <p>
                        <i className="fa fa-envelope" />
                        <strong>Email</strong>
                      </p>
                      <h5 style={{ color: '#999999' }}>
                        moysesimoveis@yahoo.com.br
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurSocialMedias />
      <Footer />
    </div>
  );
};

const TextField = styled(MuiTextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: 'var(--main-color-one)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d4d4',
    },
    '&:hover fieldset': {
      borderColor: 'var(--main-color-one)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--main-color-one)',
    },
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const FormControlLabel = styled(MuiFormControlLabel)({
  marginTop: '1rem',
  marginRight: '0',

  '.MuiFormControlLabel-label': {
    fontSize: '.7rem',
  },
});

export default SellProperty;
