import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import MoysesLogoMobile from './../../../assets/images/moyses-logo.png';
import MoysesLogoM from './../../../assets/images/moyses-logo-m.png';
import { ReactComponent as LocationIcon } from './../../../assets/icons/location-icon.svg';

import './Footer.scss';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);

  const year = new Date().getFullYear();

  function checkScreenWidth() {
    window.innerWidth < 576 ? setIsMobile(true) : setIsMobile(false);
  }

  useEffect(() => {
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
  }, []);

  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL + '/';
    const minscript = document.createElement('script');
    minscript.async = true;
    minscript.src = publicUrl + 'assets/js/main.js';

    document.body.appendChild(minscript);
  }, []);

  const linksMapper = {
    institutional: [
      {
        title: 'Sobre a empresa',
        url: '/sobre-nos',
      },
      {
        title: 'Fale Conosco',
        url: '/fale-conosco',
      },
      {
        title: 'Trabalhe Conosco',
        url: 'https://docs.google.com/forms/d/e/1FAIpQLSdmygd_7qhtqSgS1WSYk14kmPGqzx_SQpTXkHYobvfMa0f-eA/viewform',
      },
      {
        title: 'Política de Privacidade',
        url: 'https://drive.google.com/file/d/1ZTw4JFZHBPU5U52p6MDe_7YMxeUgsHSM/view?usp=sharing',
      },
      {
        title: 'Blog + que Imóveis',
        url: '/blog',
      },
    ],
    propertiesForSale: [
      {
        title: 'Imóveis Residenciais',
        url: '/imoveis?category=residencial',
      },
      {
        title: 'Imóveis Comerciais',
        url: '/imoveis?category=comercial',
      },
    ],
    sellYourProperty: [
      {
        title: 'Anunciar Imóveis',
        url: '/venda-seu-imovel',
      },
      {
        title: 'Solicitar uma Avaliação',
        url: '/solicitar-avaliacao',
      },
    ],
    general: [
      {
        title: 'Simulador de financiamento',
        url: '/simular-financiamento',
      },
      {
        title: 'Dúvidas Frequentes',
        url: '/duvidas-frequentes',
      },
      {
        title: 'Links Úteis',
        url: '/links-uteis',
      },
    ],
  };
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-bottom" style={{ maxWidth: '100%' }}>
          <a
            className="footer-logo"
            href="#"
            style={{ margin: isMobile && '0 auto 56px' }}
          >
            {isMobile ? (
              <img alt="logo" src={MoysesLogoMobile} />
            ) : (
              <img alt="logo" src={MoysesLogoM} style={{ width: '65px' }} />
            )}
          </a>
          <div
            className="row"
            style={{ justifyContent: isMobile ? 'center' : 'space-between' }}
          >
            <div className="col-lg-3 col-sm-6 footer-card">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Institucional</h4>
                <ul>
                  {linksMapper.institutional.map((item, i) => {
                    const external = item.url.includes('https');

                    return (
                      <li className="readeal-top" key={i}>
                        {external ? (
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {item.title}
                          </a>
                        ) : (
                          <Link to={item.url}>{item.title}</Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 footer-card">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Imóveis à venda</h4>
                <ul>
                  {linksMapper.propertiesForSale.map((item, i) => {
                    return (
                      <li className="readeal-top" key={i}>
                        <a
                          href={item.url}
                          target={`${item.target ? item.target : ''}`}
                        >
                          {item.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 footer-card">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Venda seu imóvel</h4>
                <ul>
                  {linksMapper.sellYourProperty.map((item, i) => (
                    <li className="readeal-top" key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 footer-card">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Geral</h4>
                <ul>
                  {linksMapper.general.map((item, i) => (
                    <li className="readeal-top" key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 footer-card">
              <div className="widget widget_nav_menu location-infos">
                <h4 className="widget-title">Atendimento:</h4>
                <ul>
                  <li className="readeal-top">De segunda a sexta-feira</li>
                  <li className="readeal-top">
                    <strong>8h às 12h - 13h às 17h</strong>
                  </li>
                  <li>Alm. Barroso, 1707 - Pelotas/RS</li>
                  <li className="different-link">
                    <a
                      href="https://www.google.com/maps/place/R.+Alm.+Barroso,+1707+-+Centro,+Pelotas+-+RS,+96010-280/data=!4m2!3m1!1s0x9511b5be50460bb1:0x93ea91207a5f9beb?sa=X&ved=2ahUKEwj_j-yK7O7zAhUPp5UCHXGdBaEQ8gF6BAgFEAE"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LocationIcon color="var(--main-color-one)" />

                      <strong>Como chegar</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="copy-right text-center">
          Feito com ❤ em Pelotas - Todos os Direitos Reservados ® - {year}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
