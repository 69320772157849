import React, { useEffect, useState } from 'react';

import { Formik, FastField } from 'formik';
import { styled } from '@mui/material/styles';

import { TextField as MuiTextField } from 'formik-mui';

import InputMask from 'react-input-mask';
import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import Header from '../../components/global-components/Header/Header';
import Navbar from '../../components/global-components/Navbar/Navbar';
import PageInfos from '../../components/global-components/PageInfos/PageInfos.jsx';
import { SingleProperty } from '../../components/section-components/SingleProperty/SingleProperty';
import OurSocialMedias from '../../components/section-components/our-social-medias';
import Footer from '../../components/global-components/Footer/Footer.jsx';

import WhatsappStation from '../../components/global-components/WhatsappStation/WhatsappStation.jsx';

import { Api } from '../../Api';

import './FavoriteProperties.scss';
import { useFavoriteProperties } from '../../context/properties';

const FavoriteProperties = () => {
  const [favoritesCode, setFavoritesCode] = useState([]);

  const { favoriteProperties, setFavoriteProperties } = useFavoriteProperties();

  const [status, setStatus] = useState();

  const initialValues = {
    subject: '',
    name: '',
    email: '',
    phone: '',
    message: '',
    agreed: true,
  };

  function sendContact(values) {
    Api.sendContactForm(values, setStatus);
  }

  function removeFavorite(item) {
    setFavoriteProperties((oldState) =>
      oldState.filter((x) => x.id !== item.id)
    );
  }

  return (
    <div>
      <Header />
      <Navbar />
      <PageInfos currentPageName="Imóveis Favoritos" />
      <div className="property-details-area">
        <div className="pd-top-responsive pd-bottom-60">
          <div className="container">
            <WhatsappStation />

            <div className="row">
              {favoriteProperties.length >= 0 ? (
                <p className="favorite-count">
                  Encontramos <span>{favoriteProperties.length}</span>
                  {favoriteProperties.length > 1
                    ? ' propriedades'
                    : ' propriedade'}
                  {favoriteProperties.length > 1
                    ? ' favoritadas'
                    : ' favoritada'}
                </p>
              ) : (
                <p className="favorite-count">
                  Nenhuma propriedade favoritada encontrada
                </p>
              )}
              <div className="col-xl-9 col-lg-8 custom-medias-container padding-top-responsive">
                {favoriteProperties.map((item) => {
                  return (
                    <SingleProperty
                      key={item.code}
                      property={item}
                      isFavorite={
                        favoriteProperties.findIndex(
                          (el) => el.id === item.id
                        ) > -1
                      }
                      toggleFavorite={() => removeFavorite(item)}
                    />
                  );
                })}
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="widget widget-owner-info mt-lg-0 mt-5">
                  <Formik
                    validateOnBlur
                    initialValues={initialValues}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = 'Campo obrigatório';
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = 'E-mail inválido';
                      }

                      if (!values.name) {
                        errors.name = 'Campo obrigatório';
                      }

                      if (!values.phone) {
                        errors.phone = 'Campo obrigatório';
                      } else if (
                        !/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/g.test(values.phone)
                      ) {
                        errors.phone = 'Número inválido';
                      }

                      if (!values.agreed) {
                        errors.agreed = 'Campo obrigatório';
                      }

                      return errors;
                    }}
                    onSubmit={(values, { resetForm }) => {
                      sendContact({
                        subject: `Contato — Favoritos — ${values.name} `,
                        name: values.name,
                        email: values.email,
                        phone: values.phone,
                        message: values.message,
                      });
                      resetForm();
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => {
                      return (
                        <form
                          onSubmit={handleSubmit}
                          className="property-details-form__contact"
                        >
                          {status === undefined ? (
                            <div className="contact">
                              <div className="mt-18">
                                <FastField
                                  component={TextField}
                                  className="test-input"
                                  variant="outlined"
                                  name="name"
                                  values={values.name}
                                  onChange={handleChange}
                                  label="Nome*"
                                />
                              </div>
                              <div className="mt-18">
                                <InputMask
                                  mask="(99) 99999-9999"
                                  value={values.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {(inputProps) => (
                                    <FastField
                                      component={TextField}
                                      className="test-input"
                                      variant="outlined"
                                      name="phone"
                                      label="Seu Telefone*"
                                      inputProps={{ ...inputProps }}
                                    />
                                  )}
                                </InputMask>
                              </div>
                              <div className="mt-18">
                                <FastField
                                  component={TextField}
                                  onChange={handleChange}
                                  values={values.email}
                                  className="test-input"
                                  type="email"
                                  variant="outlined"
                                  name="email"
                                  label="Seu Email*"
                                />
                              </div>

                              <div className="mt-18">
                                <FastField
                                  component={TextField}
                                  onChange={handleChange}
                                  values={values.message}
                                  className="test-input"
                                  multiline
                                  type="text"
                                  variant="outlined"
                                  name="message"
                                  label="Mensagem"
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  classes="checkbox-label"
                                  control={
                                    <Checkbox
                                      name="agreed"
                                      checked={values.agreed}
                                      onChange={handleChange}
                                      sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                          color: 'var(--main-color-one)',
                                        },
                                      }}
                                    />
                                  }
                                  label="Dados utilizados exclusivamente pela Moysés Imóveis para
                  responder seu contato e envio de informações do mercado
                  imobiliário."
                                />
                                {errors.agreed && (
                                  <p className="input-error">{errors.agreed}</p>
                                )}
                              </div>

                              <button
                                type="submit"
                                className="btn btn-yellow mt-18 sell-property-form__contact__button"
                              >
                                SOLICITAR CONTATO
                              </button>
                              <p className="input-error">
                                {touched.name &&
                                  !values.name &&
                                  touched.phone &&
                                  !values.phone &&
                                  touched.email &&
                                  !values.email &&
                                  touched.agreed &&
                                  !values.agreed &&
                                  'Preencha os campos obrigatórios'}
                              </p>
                            </div>
                          ) : status >= 200 && status < 300 ? (
                            <div className="status-wrapper">
                              <CheckCircleIcon />
                              <p>Formulário enviado!</p>
                            </div>
                          ) : (
                            <div className="status-wrapper">
                              <ErrorIcon />
                              <p>Ocorreu algum erro!</p>
                              <p>Tente novamente mais tarde.</p>
                            </div>
                          )}
                        </form>
                      );
                    }}
                  </Formik>
                  <div className="contact-info">
                    <h6 className="contact-info__title">
                      Informações de contato
                    </h6>
                    <div className="media">
                      <div className="media-left custom-icon">
                        <i className="fa fa-phone" />
                      </div>
                      <div className="media-body">
                        <p className="contact-info__subtitle">Telefone</p>
                        <span className="contact-info__specific-info">
                          (53) 3028-5706
                        </span>
                      </div>
                    </div>
                    <div className="media mb-0">
                      <div className="media-left custom-icon">
                        <i className="fa fa-envelope" />
                      </div>
                      <div className="media-body">
                        <p className="contact-info__subtitle">Email</p>
                        <span className="contact-info__specific-info">
                          moysesimoveis@yahoo.com.br
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurSocialMedias />
      <Footer />
    </div>
  );
};

const TextField = styled(MuiTextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: 'var(--main-color-one)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d4d4',
    },
    '&:hover fieldset': {
      borderColor: 'var(--main-color-one)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--main-color-one)',
    },
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const FormControlLabel = styled(MuiFormControlLabel)({
  marginTop: '1rem',
  marginRight: '0',

  '.MuiFormControlLabel-label': {
    fontSize: '.7rem',
  },
});

export default FavoriteProperties;
