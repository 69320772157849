import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../../../Api';
import AnimateHeight from 'react-animate-height';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import styled from 'styled-components';

import './Testimonials.scss';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [open, setOpen] = useState({});

  const getTestimonials = () => {
    Api.getTestimonials().then((data) => {
      setTestimonials(data);
    });
  };

  function handleTestimonyOpen(index) {
    if (!open[index]) {
      setOpen((oldState) => ({ ...oldState, [index]: true }));
    }

    if (open[index]) {
      setOpen((oldState) => ({ ...oldState, [index]: false }));
    }
  }

  useEffect(() => {
    getTestimonials();
  }, []);

  if (testimonials.length === 0) return null;

  return (
    <div className="client-area pd-top-90 pd-bottom-100">
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title">Quem é cliente, recomenda!</h2>
        </div>
        <div className="client-review-img">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="client-slider-2 text-center">
                {testimonials.map((item, i) => (
                  <div key={i} className="item">
                    <div className="single-client-review">
                      <div className="thumb">
                        <img src={item.image} alt={item.name} />
                      </div>
                      <div className="review-details">
                        <h4>{item.name}</h4>
                        <AnimateHeight
                          className="animate-panel"
                          duration={500}
                          height={open[i] ? 'auto' : 81} // see props documentation below
                        >
                          <Text open={open[i]}>{item.text} </Text>
                        </AnimateHeight>
                      </div>
                      <IconContext.Provider
                        value={{ className: 'arrow-button' }}
                      >
                        <div>
                          {open[i] ? (
                            <BsChevronUp
                              onClick={() => handleTestimonyOpen(i)}
                            />
                          ) : (
                            <BsChevronDown
                              onClick={() => handleTestimonyOpen(i)}
                            />
                          )}
                        </div>
                      </IconContext.Provider>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="call-to-action-container">
          <Link className="navigation-phrase" to="/fale-conosco">
            Conte sua experiência{' '}
            <i
              className="la la-long-arrow-right"
              style={{ color: 'var(--main-color-one)' }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

const Text = styled.p`
  display: block;
  display: -webkit-box;
  margin: 0;
  -webkit-line-clamp: ${({ open }) => (open ? 'none' : 3)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Testimonials;
