import React, { useState, useLayoutEffect, useEffect } from 'react';

import Navbar from '../components/global-components/Navbar/Navbar';
import PopularPost from '../components/blog-components/PopularPost/PopularPost.jsx';
import PostList from '../components/blog-components/PostList/PostList';
import Footer from '../components/global-components/Footer/Footer.jsx';
import Header from '../components/global-components/Header/Header';
import PageInfos from '../components/global-components/PageInfos/PageInfos';
import OurSocialMedias from '../components/section-components/our-social-medias';

import Pagination from '../components/global-components/Pagination/Pagination.jsx';

import { Api } from '../Api';
import { Helmet } from 'react-helmet';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [postsLength, setPostsLength] = useState();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  const logo = 'https://cms.moysesimoveis.com.br/upload/logo-98239781.png';

  const numberOfPosts = 8;
  const offset = numberOfPosts * page;

  function getPosts() {
    Api.getBlogPosts(numberOfPosts, offset).then((data) => {
      setPostsLength(data.count);
      setPosts(data.posts);
    });
  }
  useEffect(() => {
    if (!Boolean(search) && !Boolean(selectedCategory)) {
      getPosts();
    }
  }, [page, search, selectedCategory]);

  function searchTerm(term, categoryId, numberOfPosts, offset) {
    Api.searchPostsContent(term, categoryId, numberOfPosts, offset).then(
      (data) => {
        setPostsLength(data.count);
        setPosts(data.posts);
      }
    );
  }

  useEffect(() => {
    if (Boolean(search) || Boolean(selectedCategory)) {
      searchTerm(search, selectedCategory, numberOfPosts, offset);
    }
  }, [search, selectedCategory, offset]);

  useEffect(() => {
    setPage(0);
  }, [search, selectedCategory]);
  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Moysés Imóveis | Blog" />
        <meta property="og:image" content={logo} />
        <meta
          property="og:description"
          content="Comprometimento, informação, lisura, segurança, seriedade e tempo são valores que pautam o nosso dia a dia na Moysés Imóveis."
        />
        <meta
          property="og:url"
          content="https://www.moysesimoveis.com.br/blog"
        />
        <meta property="og:type" content="blog" />
        <meta property="twitter:title" content="Moysés Imóveis" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={logo} />
      </Helmet>
      <Header />
      <Navbar />
      <PageInfos currentPageName="Blog + que Imóveis" />
      <PopularPost
        search={search}
        setSearch={setSearch}
        setSelectedCategory={setSelectedCategory}
      />
      <PostList posts={posts} />

      {postsLength > 0 && (
        <Pagination
          pageCount={Math.ceil(postsLength / numberOfPosts)}
          setCurrentPage={setPage}
          currentPage={page}
        />
      )}

      <OurSocialMedias />
      <Footer />
    </div>
  );
};

export default Blog;
