import axios from 'axios';
import env from './.env.json';

const currentEnvironment = env[process.env.REACT_APP_ENVIRONMENT || 'prod'];
const imagePrefix = env.imagesUrlPrefix;

class MainApi {
  constructor() {
    this.baseUrl = currentEnvironment.baseURL;
    this.imagePrefix = imagePrefix;
  }

  async getPropertiesQuantity() {
    const url = this.baseUrl + '/properties/available-quantity';
    const response = await axios.get(url);

    return response.data;
  }

  async getRecentlyProperties(query = '') {
    const url = this.baseUrl + '/properties/recently-added' + query;
    const response = await axios.get(url);

    return response.data;
  }

  async searchProperties(query, limit = 9, offset = 0, order) {
    let url = `${this.baseUrl}/properties/search?limit=${limit}&offset=${
      offset * limit
    }`;
    if (query) {
      url += `&${query}`;
    }
    if (order) {
      url += `&order=${order}`;
    }

    const response = await axios.get(url);

    return response.data;
  }

  async getPropertyById(id) {
    let url = `${this.baseUrl}/properties/${id}`;
    const response = await axios.get(url);

    return response.data;
  }

  async getPropertyDimensions(id) {
    let url = `${this.baseUrl}/properties/${id}/dimensions`;
    const response = await axios.get(url);

    return response.data;
  }

  async getBlogPosts(number, offset = 0) {
    let url = `${this.baseUrl}/posts?limit=${number}&offset=${offset}`;

    const response = await axios.get(url);

    return response.data;
  }

  async getBlogPost(id) {
    let url = `${this.baseUrl}/posts/${id}`;
    const response = await axios.get(url);

    return response.data;
  }

  async getBlogNeighborhoodPosts() {
    let url = `${this.baseUrl}/posts/category/13`;
    const response = await axios.get(url);

    return response.data;
  }

  async getDistricts() {
    let url = `${this.baseUrl}/districts`;

    const response = await axios.get(url);

    return response.data;
  }

  async getAvailableDistricts(type, subType) {
    let url = `${this.baseUrl}/districts/available`;

    if (type) {
      url += `?type=${type}`;
    }

    if (subType) {
      url += `&subtype=${subType}`;
    }
    const response = await axios.get(url);

    return response.data;
  }

  async getMinAndMaxPropertiesValues() {
    let url = `${this.baseUrl}/properties/filters-helper/price-and-area`;
    const response = await axios.get(url);

    return response.data;
  }

  async getPopularPosts(limit) {
    let url = `${this.baseUrl}/posts/most-viewed/list?limit=${limit}`;

    const response = await axios.get(url);

    return response.data;
  }

  async searchPostsContent(term, category, limit, offset = 0) {
    let url = `${this.baseUrl}/posts/search?term=${term}&limit=${limit}&offset=${offset}`;
    if (category) url += `&category_id=${category}`;

    const response = await axios.get(url);

    return response.data;
  }

  async getPostsCategories() {
    let url = `${this.baseUrl}/posts/categories/list`;
    const response = await axios.get(url);

    return response.data;
  }

  async getPostsByCategory(id) {
    let url = `${this.baseUrl}/posts/category/${id}`;
    const response = await axios.get(url);

    return response.data;
  }

  async getContributors() {
    let url = `${this.baseUrl}/contributors`;
    const response = await axios.get(url);

    return response.data;
  }

  async getUsefulLinks() {
    let url = `${this.baseUrl}/useful-links`;
    const response = await axios.get(url);

    return response.data;
  }

  async getTestimonials() {
    let url = `${this.baseUrl}/testimonials`;
    const response = await axios.get(url);

    return response.data;
  }

  async getFaq() {
    let url = `${this.baseUrl}/faq`;
    const response = await axios.get(url);

    return response.data;
  }

  async getAboutUsTimeline() {
    let url = `${this.baseUrl}/about-us`;
    const response = await axios.get(url);

    return response.data;
  }

  async getAboutUsCarousel() {
    let url = `${this.baseUrl}/carousel-about-us`;
    const response = await axios.get(url);

    return response.data;
  }

  // POST
  async sendContactForm(params, setStatus) {
    let url = `${this.baseUrl}/contact-mail/contact-us`;
    axios.post(url, params).then((response) => {
      setStatus(response.status);
    });
  }

  async sendPropertyForm(params, setStatus, setIsSending) {
    let url = `${this.baseUrl}/contact-mail/announce-property`;

    setIsSending(true);

    axios({
      method: 'post',
      url: url,
      data: params,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        setStatus(response.status);
        setIsSending(false);
      })
      .catch(() => {
        setStatus(500);
        setIsSending(false);
      });
  }
}

const Api = new MainApi();

export { Api };
