import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import env from './../../../.env.json';
import { Api } from '../../../Api';

import WhatsappStation from '../../global-components/WhatsappStation/WhatsappStation.jsx';

import './RecomandProperties.scss';
import slugify from 'slugify';

function RecomandProperties({ property }) {
  const imagesUrlPrefix = env.imagesUrlPrefix;
  const [similarProperties, setSimilarProperties] = useState([]);
  const [similarPropertiesUrl, setSimilarPropertiesUrl] = useState('');

  const slug = (title) =>
    slugify(title, {
      lower: true,
      strip: true,
      remove: '.',
    });

  const formatSubtype = (subtype) => {
    if (subtype === 'apartamento') return 'Apartamento';
    if (subtype === 'galpao/deposito/armazem') return 'Galpão/Depósito/Armazém';
    if (subtype === 'terreno em condominio') return 'Terreno em condomínio';
    if (subtype === 'casa em condominio') return 'Casa em condomínio';

    return subtype;
  };

  const getRecomendedProperties = async () => {
    Api.getRecentlyProperties(
      `?limit=4&district=${property.district_id}&type=${property.type}&subtype=${property.subtype}&propertyId=${property.id}&bedrooms=${property.bedroom}&price=${property.price}`
    ).then((data) => {
      setSimilarProperties(data.properties);
      setSimilarPropertiesUrl(
        `?category=${property.type}&type=${property.subtype}&district=${property.district_id}`
      );
    });
  };

  useEffect(() => {
    getRecomendedProperties();
  }, []);

  if (!similarProperties) {
    return null;
  } else {
    return (
      <div className="recommended-area pd-top-responsive pd-bottom-70">
        <div className="container">
          <WhatsappStation />

          {similarPropertiesUrl && (
            <div className="section-title">
              <h2 className="custom-title">Imóveis Semelhantes</h2>
              <Link
                className="btn-view-all"
                to={`/imoveis` + similarPropertiesUrl}
              >
                Ver todos
              </Link>
            </div>
          )}
          <div className="row">
            {similarProperties.map((property, i) => (
              <div key={i} className="col-lg-3 col-sm-6">
                <Link to={`/imoveis/${property.id}/${slug(property.title)}`}>
                  <div className="single-feature">
                    <div className="thumb image-thumb">
                      <img
                        src={
                          imagesUrlPrefix +
                          (property?.cover || property?.images[0])
                        }
                        alt={property.subtype}
                      />
                    </div>
                    <div className="details image-details-feature-properties">
                      <span className="title property-title--little readeal-top">
                        {property.title}
                        {property.condominium_title &&
                        !Boolean(property.control.condominium_title)
                          ? ` - ${property.condominium_title}`
                          : ''}
                      </span>

                      <h6 className="price--little">
                        {Number(property.price).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </h6>
                      <div id="line" />
                      <div className="row infos--little">
                        <strong className="subtype">
                          {formatSubtype(property.subtype)} / {property.bedroom}{' '}
                          dorm
                        </strong>
                        <span>Cód: {property.code}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default RecomandProperties;
