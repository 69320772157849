import React from 'react';

import Header from '../components/global-components/Header/Header';
import Navbar from '../components/global-components/Navbar/Navbar';
import OurSocialMedias from '../components/section-components/our-social-medias';
import SearchGridSection from '../components/section-components/SearchGridSection/SearchGridSection';
import Footer from '../components/global-components/Footer/Footer.jsx';
import PageInfos from '../components/global-components/PageInfos/PageInfos.jsx';
import { Helmet } from 'react-helmet';

function SearchGrid() {
  const logo = 'https://cms.moysesimoveis.com.br/upload/logo-98239781.png';
  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Moysés Imóveis" />
        <meta property="og:image" content={logo} />
        <meta
          property="og:description"
          content="Comprometimento, informação, lisura, segurança, seriedade e tempo são valores que pautam o nosso dia a dia na Moysés Imóveis."
        />
        <meta
          property="og:url"
          content="https://www.moysesimoveis.com.br/imoveis"
        />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content="Moysés Imóveis" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={logo} />
      </Helmet>
      <Header />
      <Navbar />
      <PageInfos currentPageName="Imóveis Disponíveis" />
      <SearchGridSection />
      <OurSocialMedias />
      <Footer />
    </div>
  );
}

export default SearchGrid;
