import React from 'react';

import Navbar from '../components/global-components/Navbar/Navbar';
import NewsDetailsSection from '../components/blog-components/NewsDetails/NewsDetails';
import Footer from '../components/global-components/Footer/Footer.jsx';
import Header from '../components/global-components/Header/Header';
import PageInfos from '../components/global-components/PageInfos/PageInfos';
import { useState } from 'react';

const Post = () => {
  const [post, setPost] = useState();

  const category = post?.category?.[0]?.title;

  return (
    <div>
      <Header />
      <Navbar />
      <PageInfos currentPageName={category} />
      <NewsDetailsSection post={post} setPost={setPost} />
      <Footer />
    </div>
  );
};

export default Post;
