import React from 'react';

import MuiWhatsAppIcon from '@material-ui/icons/WhatsApp';

import { withStyles } from '@mui/styles';

import './WhatsappStation.scss';

const WhatsappStation = () => {
  const url = 'https://api.whatsapp.com/send?phone=5553981600126';

  return (
    <a
      href="https://api.whatsapp.com/send?phone=5553981600126"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-station"
    >
      <WhatsAppIcon fontSize="large" />
    </a>
  );
};

const WhatsAppIcon = withStyles(() => ({
  root: {
    color: 'white',
  },
  fontSizeLarge: {
    fontSize: '2.25rem !important',
  },
}))(MuiWhatsAppIcon);

export default WhatsappStation;
