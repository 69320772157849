import React from 'react';
import useWindowPosition from '../../../hooks/useWindowPosition';

function toTop() {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}

const BackToTop = () => {
  const scrollPos = useWindowPosition();

  return (
    <div
      className="back-to-top"
      onClick={() => toTop()}
      style={{ display: scrollPos < 1000 ? 'none' : 'block' }}
    >
      <span className="back-top">
        <i className="fa fa-angle-up"></i>
      </span>
    </div>
  );
};

export default BackToTop;
