import React from 'react';
import { Link } from 'react-router-dom';
import './Card.scss';

import env from './../../../.env.json';

const Card = ({ post }) => {
  const postImage = env.postImage;

  const date = new Date(post?.date).toLocaleString('pt-BR', {
    dateStyle: 'short',
  });
  return (
    <div className="card-container">
      <Link to={`/blog/post/${post.id}/${post.slug}`} className="card-link">
        <div className="card-image">
          <img src={postImage + post.image} />
        </div>
        <div className="card-text">
          <span id="card-title">{post.title}</span>
          <span id="card-date">{date}</span>
        </div>
      </Link>
    </div>
  );
};

export default Card;
