import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import readingTime from 'reading-time';
import env from './../../../.env.json';

import './PostList.scss';

const PostList = ({ posts }) => {
  const postImage = env.postImage;

  return (
    <div className="blog-area">
      <div className="property-news-area pd-top-responsive">
        <div className="container">
          <div className="row">
            {posts.map((item) => {
              const formattedDate = item?.created_at.replace('Z', '');

              const date = new Date(formattedDate).toLocaleString('pt-BR', {
                dateStyle: 'short',
                timeStyle: 'short',
              });

              const { minutes } = readingTime(item.body, {
                wordsPerMinute: 300,
              });

              const roundedMinutes = Math.round(minutes);

              return (
                <div key={item.id} className="col-lg-6">
                  <Link to={`/blog/post/${item?.id}/${item?.slug}`}>
                    <div className="single-news">
                      <div className="thumb">
                        <img src={postImage + item.image} />
                      </div>
                      <div className="details" style={{ height: '250px' }}>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                        <div className="details-description">
                          <div className="author">
                            <span className="date">{date}</span>
                          </div>
                          <span>{roundedMinutes} min. de leitura</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>

          {posts.length === 0 && <p>Nenhum Post encontrado</p>}
        </div>
      </div>
    </div>
  );
};

export default PostList;
