import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { ReactComponent as InstagramIcon } from '../../assets/icons/insta-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin-icon.svg';
import { ReactComponent as WhatsappGoldIcon } from '../../assets/icons/whatsapp-gold-icon.svg';

import { Api } from '../../Api';

const OurPartner = () => {
  const staticSocialMedias = [
    {
      icon: <InstagramIcon color="var(--main-color-one)" />,
      title: 'Instagram',
      contact: '/moysesimoveis',
      url: 'https://www.google.com.br',
    },
    {
      icon: <YoutubeIcon color="var(--main-color-one)" />,
      title: 'YouTube',
      contact: '/moysesimoveis',
      url: 'https://www.google.com.br',
    },
    {
      icon: <LinkedinIcon color="var(--main-color-one)" />,
      title: 'Linkedin',
      contact: '/moysesimoveis',
      url: 'https://www.google.com.br',
    },
    {
      icon: <WhatsappGoldIcon color="var(--main-color-one)" />,
      title: 'WhatsApp',
      contact: '(53) 9 8160-0126',
      url: 'https://wa.me/5553981600126',
    },
  ];

  const [socialMedias, setsocialMedias] = useState([]);

  useEffect(() => {
    Api.getUsefulLinks().then(({ socialMedia }) =>
      socialMedia.filter((ApiItem) => {
        staticSocialMedias.filter((staticItem, i) => {
          if (ApiItem.title === staticItem.title) {
            setsocialMedias((oldState) => [
              ...oldState,
              { ...staticItem, url: ApiItem.link },
            ]);
          }
        });
      })
    );
  }, []);

  function whichContactShouldRender(item) {
    const url = item.url?.match(/[0-9]{12,13}/)?.[0].slice(2);
    if (item.title === 'WhatsApp') {
      return (
        <NumberFormat
          style={{
            margin: '0',
            lineHeight: '100%',
          }}
          value={url}
          displayType={'text'}
          format="(##) # ####-####"
        />
      );
    }
    if (item.title === 'Instagram') {
      return (
        <span
          style={{
            color: '#999999',
            margin: '0',
            lineHeight: '100%',
          }}
        >
          /moysesimoveis
        </span>
      );
    }
    if (item.title === 'YouTube') {
      return (
        <span
          style={{
            color: '#999999',
            margin: '0',
            lineHeight: '100%',
          }}
        >
          /moysesimoveis
        </span>
      );
    }
    if (item.title === 'Linkedin') {
      return (
        <span
          style={{
            color: '#999999',
            margin: '0',
            lineHeight: '100%',
          }}
        >
          /moysesimoveis
        </span>
      );
    }
  }

  return (
    <div className="client-area pd-top-65" style={{ paddingBottom: '170px' }}>
      <div className="container">
        <div className="section-title text-center">
          <h2
            className="title"
            style={{
              color: '#0E0E0E',
              marginBottom: '80px',
              lineHeight: '120%',
              fontSize: '2.5rem',
              fontWeight: 'normal',
            }}
          >
            Nossas Redes
          </h2>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            gridGap: '2rem',
          }}
        >
          {socialMedias.map((item, i) => {
            return (
              <a
                href={item.url}
                rel="noopener noreferrer"
                target="_blank"
                key={i}
                style={{ display: 'flex' }}
              >
                <div className="thumb" style={{ height: '40px' }}>
                  {item.icon}
                </div>
                <div
                  className="col"
                  style={{ color: '#999999', fontWeight: 'bold' }}
                >
                  <h5
                    style={{
                      color: '#999999',
                      margin: '0',
                      lineHeight: '100%',
                    }}
                  >
                    {item.title}
                  </h5>

                  {whichContactShouldRender(item)}
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OurPartner;
