import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './PageInfos.scss';

function PageInfos(props) {
  const { currentPageName } = props;
  const { pathname, search } = useLocation();

  return (
    <div className="page-infos-container">
      <div className="page-infos">
        {pathname.includes('/blog/post') ? (
          <h1 className="page-infos__page-name">{currentPageName}</h1>
        ) : (
          <h1 className="page-infos__page-name">{currentPageName}</h1>
        )}

        <span className="page-infos__navigation-infos">
          <Link to="/">Home</Link>

          {pathname.includes('/blog/post') ? (
            <>
              <Link to="/blog">Blog + que Imóveis</Link>
              <Link to={pathname + search}>Post</Link>
            </>
          ) : (
            <Link to={pathname + search}>{currentPageName}</Link>
          )}
        </span>
      </div>
    </div>
  );
}

export default PageInfos;
