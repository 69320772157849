import React from 'react';

import MultiSelectUnstyled from '@mui/base/MultiSelectUnstyled';

import OptionUnstyled, {
  optionUnstyledClasses,
} from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';

import { styled } from '@mui/system';

export const BpIcon = styled('span')({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto var(--main-color-one)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
});

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'var(--main-color-one)',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'var(--main-color-one)',
  },
});

const StyledButton = styled('button')`
  font-family: var(--heading-font);
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;

  background: #fff;
  padding: 10px;
  text-align: left;
  line-height: 1.5;

  &::after {
    content: '';
  }
`;
const StyledListbox = styled('ul')`
  font-family: var(--heading-font);
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  padding: 8px 8px 8px 16px;
  margin: 10px 0;
  width: 260.75px;
  background: #fff;
  border: 1px solid #cdd2d7;
  border-radius: 0.75em;
  color: #1a2027;
  overflow-y: scroll;
  outline: 0px;

  max-height: 300px;

  * {
    z-index: 99;
  }
`;
export const StyledOption = styled(OptionUnstyled)`
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: #c7a877;
    color: white;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #c7a877;
    color: white;
  }

  &.${optionUnstyledClasses.disabled} {
    color: #b2bac2;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: #e7ebf0;
    color: #1a2027;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 99;
`;

export const MultiSelect = React.forwardRef(function CustomMultiSelect(
  props,
  ref
) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return (
    <MultiSelectUnstyled
      {...props}
      ref={ref}
      components={components}
      componentsProps={{ popper: { disablePortal: false } }}
    />
  );
});
