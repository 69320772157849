import React, { useState } from 'react';

import './CookiesBar.scss';

const CookiesBar = () => {
  const [isAccept, setIsAccept] = useState(null);

  const onClick = () => {
    setIsAccept(true);

    localStorage.setItem('@MOYSÉS:privacy-policy', 'accept');
  };

  return (
    <>
      {!isAccept && !localStorage.getItem('@MOYSÉS:privacy-policy') && (
        <div className="cookies-row">
          <div className="cookies-container">
            <p className="cookies-row_description">
              Utilizamos cookies para melhorar a sua experiência no site. Para
              saber mais, acesse a nossa{' '}
              <a
                href="https://drive.google.com/file/d/1ZTw4JFZHBPU5U52p6MDe_7YMxeUgsHSM/view?usp=sharing"
                className="cookies-row_link"
                target="_blank"
                rel="noreferrer noopener"
              >
                Política de Privacidade
              </a>
            </p>
            <button className="cookies-row_button" onClick={onClick}>
              Aceitar e fechar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesBar;
