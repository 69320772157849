import React from 'react';
import sectiondata from '../../../data/sections.json';
import { BsFillLightningFill, BsBuilding, BsRecycle } from 'react-icons/bs';
import './UserList.scss';

const UserList = () => {
  let data = sectiondata.userlist;

  function whichIconShouldRender(i) {
    if (i.icon === 'sewer') {
      return <BsRecycle size="2em" />;
    }
    if (i.icon === 'lightning') {
      return <BsFillLightningFill size="2em" />;
    }
    if (i.icon === 'building') {
      return <BsBuilding size="2em" />;
    }
  }

  return (
    <div className="user-list-area pd-top-100 pd-bottom-70">
      <div className="container">
        <div className="row">
          {data.items.map((item, i) => (
            <div key={i} className="company-card col-lg-4 col-md-6">
              <div className="single-user-list text-center">
                <div className="thumb">{whichIconShouldRender(item)}</div>
                <div className="details">
                  <h4>
                    <a href={item.url} target="_blank" rel="noreferer noopener">
                      {item.name}
                    </a>
                  </h4>
                  <p>{item.address}</p>
                  <span className="phone">
                    <i className="fa fa-phone" />
                    {item.phone}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserList;
