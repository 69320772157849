import React from "react";
import Slider from "@material-ui/core/Slider";
import NumberFormat from "react-number-format";

const InputDoubleSlider = ({
  minMaxValue,
  visibleValue,
  setVisibleValue,
  step,
  setFieldValue,
  name,
  area,
  hasMaxSufix,
}) => {
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  const getSecondSufix = () => {
    if (hasMaxSufix && minMaxValue[1] === visibleValue[1]) return "+";
    if (!area) return "";

    return "m²";
  };

  return (
    <>
      <div className="prices-container">
        <NumberFormat
          value={visibleValue[0]}
          className="prices-container__price"
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix={!area ? "R$ " : ""}
          suffix={!area ? "" : "m²"}
        />
        <NumberFormat
          value={visibleValue[1]}
          displayType="text"
          className="prices-container__price"
          thousandSeparator="."
          decimalSeparator=","
          prefix={!area ? "R$ " : ""}
          suffix={getSecondSufix()}
        />
      </div>
      <div className="slider-mui">
        <Slider
          name={name}
          value={[visibleValue[0], visibleValue[1]]}
          min={minMaxValue[0]}
          step={step}
          max={minMaxValue[1]}
          valueLabelFormat={numFormatter}
          onChange={(_, newValue) => setVisibleValue(newValue)}
          onChangeCommitted={(_, newValue) => setFieldValue(name, newValue)}
          valueLabelDisplay="off"
          aria-labelledby="non-linear-slider"
        />
      </div>
    </>
  );
};

export default InputDoubleSlider;
