import React from 'react';
import { Link } from 'react-router-dom';

import { FavoriteHeart } from '../FavoriteHeart/FavoriteHeart';

import './SingleProperty.scss';
import sanitizeHtml from 'sanitize-html';
import slugify from 'slugify';
import ImageWithFallback from './../../global-components/ImageWithFallback/index';

function SingleProperty({ property, isFavorite, toggleFavorite }) {
  const control = property?.control;

  const description = property && sanitizeHtml(property.description);

  const slug = slugify(property.title, {
    lower: true,
    strip: true,
    remove: '.',
  });

  return (
    <div className="single-property">
      <ImageWithFallback
        src={property.image}
        className="single-property__image"
      />

      <div className="single-property__container">
        <header className="single-property__container__header">
          <h3 className="single-property__container__header__title">
            {property.title}
            {property.condominium_title &&
            !Boolean(property.control.condominium_title)
              ? ` - ${property.condominium_title}`
              : ''}
          </h3>
          <FavoriteHeart isActive={isFavorite} handleClick={toggleFavorite} />
        </header>
        <div className="single-property__container__infos">
          <span className="single-property__container__infos__price">
            {Boolean(control.price)
              ? 'Valor a consultar'
              : Number(property.price).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
          </span>
          <span className="single-property__container__infos__code">
            Cód: {property.code}
          </span>
        </div>
        <div className="single-property__container__about">
          {!Boolean(control.bedroom) && (
            <span>
              Dorm: <span className="bold">{property.bedroom}</span>
            </span>
          )}

          {!Boolean(control.garage_vacancy) && (
            <span>
              Vagas de garagem:{' '}
              <span className="bold">{property.garage_vacancy}</span>
            </span>
          )}

          {!Boolean(control.bathroom) && (
            <span>
              Banheiros: <span className="bold">{property.bathroom}</span>
            </span>
          )}
        </div>

        <div
          className="single-property__container__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Link
          className="single-property__container__button"
          to={`/imoveis/${property.id}/${slug}`}
        >
          Ver detalhes
        </Link>
      </div>
    </div>
  );
}

export { SingleProperty };
