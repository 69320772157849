import React from 'react';

import Header from '../components/global-components/Header/Header';
import Navbar from '../components/global-components/Navbar/Navbar';
import PageInfos from '../components/global-components/PageInfos/PageInfos';
import FaqSection from '../components/section-components/faq';
import OurSocialMedias from '../components/section-components/our-social-medias';
import Footer from '../components/global-components/Footer/Footer.jsx';

import WhatsappStation from '../components/global-components/WhatsappStation/WhatsappStation.jsx';

const Faq = () => {
  return (
    <div>
      <WhatsappStation />

      <Header />
      <Navbar />
      <PageInfos currentPageName="Dúvidas frequentes" />
      <FaqSection />
      <OurSocialMedias />
      <Footer />
    </div>
  );
};

export default Faq;
