import React, { useState, useEffect } from 'react';

import './PropertyValuesAndInfos.scss';

function PropertyValuesAndInfos({ property, propertyType }) {
  const [infoProperty, setInfoProperty] = useState();
  const control = property?.control;
  function handleBedrooms() {
    let suite = property?.suite;
    let bedroom = property?.bedroom;

    if (suite == 0 && !Boolean(control.bedroom)) {
      return `${bedroom}`;
    } else if (suite == 1 && !Boolean(control.suite)) {
      if (Boolean(control.bedroom)) {
        return `${property.suite} suíte`;
      }
      return `${property.bedroom} sendo ${property.suite} suíte`;
    } else if (suite > 1 && !Boolean(control.suite)) {
      if (Boolean(control.bedroom)) {
        return `${property.suite} suítes`;
      }
      return `${property.bedroom} sendo ${property.suite} suítes`;
    } else if (Boolean(control.suite)) {
      return `${bedroom}`;
    }
  }

  const formatSubtype = (subtype) => {
    if (subtype === 'apartamento') return 'Apartamento';
    if (subtype === 'galpao/deposito/armazem') return 'Galpão/Depósito/Armazém';
    if (subtype === 'terreno em condominio') return 'Terreno em condomínio';
    if (subtype === 'casa em condominio') return 'Casa em condomínio';
    if (subtype === 'sitio') return 'Sítio';
    if (subtype === 'predio comercial') return 'Prédio comercial';

    return subtype;
  };

  const valuesAndInfosSections = [
    {
      title: 'Valores',
      sections: [
        {
          subtitle: 'Venda',
          value: property.price ? property.price : 0,
        },
      ],
    },
    {
      title: 'Taxas',
      sections: [
        {
          subtitle: 'IPTU',
          value: property.iptu ? property.iptu : null,
        },
        {
          subtitle: 'Condomínio',
          value: property.condominium ? property.condominium : 0,
        },
      ],
    },
  ];
  const AptinfoSection = [
    {
      label: 'Tipo',
      value: formatSubtype(property.subtype),
    },
    {
      label: 'Número de dormitórios',
      value: handleBedrooms(),
    },
    {
      label: 'Área privativa',
      value: property.minor_area ? property.minor_area + 'm²' : 0,
    },

    {
      label: 'Área total',
      value: property.major_area ? property.major_area + 'm²' : 0,
    },
    {
      label: 'Vagas de garagem',
      value: property.garage_vacancy
        ? property.garage_vacancy + ' vaga(s)'
        : 0 + 'vagas',
    },

    { label: 'Banheiros', value: property.bathroom ? property.bathroom : 0 },
  ];

  const fieldInfoSection = [
    {
      label: 'Tipo',
      value: formatSubtype(property.subtype),
    },

    {
      label: 'Área total',
      value: property.major_area ? property.major_area + 'm²' : 0,
    },
  ];

  const defineArea = (subtype) => {
    if (
      subtype === 'kitnet' ||
      subtype === 'loft' ||
      subtype === 'studio' ||
      subtype === 'cobertura'
    ) {
      return 'Área privativa';
    }

    return 'Área construída';
  };

  const houseInfoSection = [
    {
      label: 'Tipo',
      value: formatSubtype(property.subtype),
    },
    {
      label: 'Número de dormitórios',
      value: handleBedrooms(),
    },
    {
      label: defineArea(property.subtype),
      value: property.minor_area ? property.minor_area + 'm²' : 0,
    },

    {
      label: 'Área total',
      value: property.major_area ? property.major_area + 'm²' : 0,
    },
    {
      label: 'Vagas de garagem',
      value: property.garage_vacancy
        ? property.garage_vacancy + ' vaga(s)'
        : 0 + 'vagas',
    },

    { label: 'Banheiros', value: property.bathroom ? property.bathroom : 0 },
  ];

  useEffect(() => {
    if (propertyType === 'apartamento') {
      setInfoProperty(AptinfoSection);
    } else if (propertyType === 'terreno') {
      setInfoProperty(fieldInfoSection);
    } else {
      setInfoProperty(houseInfoSection);
    }
  }, [propertyType]);

  return (
    <div className="container pd-top-responsive">
      <div className="property-values-and-infos">
        {!Boolean(control.price) && (
          <div className="property-values-and-infos__values little-container-default">
            {valuesAndInfosSections &&
              valuesAndInfosSections.map((data) => {
                let noTaxes = data.sections.every(
                  (section) => !Number(section.value)
                );
                if (data.title === 'Taxas' && noTaxes) {
                  return;
                }

                return (
                  <div
                    key={data.title}
                    className="property-values-and-infos__values__prices"
                  >
                    <h2 className="my-custom-title">{data.title}</h2>
                    {data.sections &&
                      data.sections.map((section, i) => {
                        if (
                          (section.subtitle === 'Condomínio' &&
                            Boolean(control.condominium)) ||
                          !Number(section.value)
                        ) {
                          return null;
                        }
                        if (
                          section.subtitle === 'IPTU' &&
                          !Number(section.value)
                        ) {
                          return null;
                        }
                        return (
                          <div
                            key={i}
                            className="property-values-and-infos__values__prices__info"
                          >
                            <p className="my-custom-subtitle">
                              {section.subtitle}
                            </p>
                            <h3 className="property-values-and-infos__values__prices__info__value">
                              {Number(section.value).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </h3>
                          </div>
                        );
                      })}
                  </div>
                );
              })}

            <div className="little-container-default warning-container">
              <span className="my-custom-subtitle">
                *Os valores e imóveis divulgados em nosso site podem sofrer
                alterações.
              </span>
            </div>
          </div>
        )}

        <div
          className={
            propertyType === 'terreno'
              ? 'property-values-and-infos__infos-field little-container-default'
              : 'property-values-and-infos__infos little-container-default'
          }
        >
          {infoProperty &&
            infoProperty.map((infoSection) => {
              if (infoSection.label === 'Número de dormitórios') {
                if (Boolean(control.bedroom) && Boolean(control.suite)) {
                  return null;
                }
                if (Boolean(control.bedroom) && property.suite == 0) {
                  return null;
                }
              }

              if (
                infoSection.label === 'Área privativa' ||
                infoSection.label === 'Área construída'
              ) {
                if (Boolean(control.minor_area)) {
                  return null;
                }
              }
              if (infoSection.label === 'Área total') {
                if (Boolean(control.major_area)) {
                  return null;
                }
              }

              if (infoSection.label === 'Banheiros') {
                if (Boolean(control.bathroom)) {
                  return null;
                }
              }

              if (infoSection.label === 'Vagas de garagem') {
                if (
                  Boolean(control.garage_vacancy) ||
                  property.garage_vacancy === '0'
                ) {
                  return null;
                }
              }
              return (
                <div
                  key={infoSection.label}
                  className={
                    propertyType === 'terreno'
                      ? 'property-values-and-infos__infos__values little-container-default info-sections-wrapper-field'
                      : 'property-values-and-infos__infos__values little-container-default info-sections-wrapper'
                  }
                >
                  <p className="my-custom-subtitle">{infoSection.label}</p>
                  <h2 className="my-custom-title">{infoSection.value}</h2>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default PropertyValuesAndInfos;
