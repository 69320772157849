import React from 'react';
import ReactPaginate from 'react-paginate';

import { ReactComponent as ChevronRight } from './../../../assets/icons/chevron-right-icon.svg';
import { ReactComponent as ChevronLeft } from './../../../assets/icons/chevron-left-icon.svg';

import './Pagination.scss';

function Pagination({ pageCount, currentPage, setCurrentPage }) {
  function goToPageTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    goToPageTop();
  };

  return (
    <ReactPaginate
      previousLabel={<ChevronLeft />}
      nextLabel={<ChevronRight />}
      breakLabel="..."
      containerClassName="ms-pagination"
      pageClassName="ms-pagination__item"
      breakClassName="ms-pagination__item"
      previousClassName="ms-pagination__item previous-page"
      nextClassName="ms-pagination__item next-page"
      activeClassName="ms-pagination__item active"
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      forcePage={currentPage}
    />
  );
}

export default Pagination;
