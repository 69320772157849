import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import './Slideshow.scss';

function Slideshow({ slideImages }) {
  return (
    <div className="slide-container">
      <Slide>
        {slideImages.map((slideImage, index) => (
          <div className="each-slide" key={index}>
            <img src={slideImage} />
          </div>
        ))}
      </Slide>
    </div>
  );
}

export default Slideshow;
