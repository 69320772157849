import React, { useState } from 'react';

import { Formik, FastField } from 'formik';
import InputMask from 'react-input-mask';
import { TextField as MuiTextField } from 'formik-mui';

import { styled } from '@mui/material/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

import Header from '../../components/global-components/Header/Header';
import Navbar from '../../components/global-components/Navbar/Navbar';
import PageInfos from '../../components/global-components/PageInfos/PageInfos';
import OurSocialMedias from '../../components/section-components/our-social-medias';
import Footer from '../../components/global-components/Footer/Footer.jsx';
import WhatsappStation from '../../components/global-components/WhatsappStation/WhatsappStation.jsx';

import eduardoImg from '../../assets/images/EDUARDO_CAMISA.png';
import elizangelaImg from '../../assets/images/ELIZANGELA_CAMISA.png';
import moysesImg from '../../assets/images/MOYSES_CAMISA.png';

import './EvaluationPage.scss';
import { Api } from '../../Api';

function EvaluationPage() {
  const evaluators = [
    { name: 'Eduardo Katz ', cnai: '37913', image: eduardoImg },
    { name: 'Elizangela Medeiros', cnai: '37911', image: elizangelaImg },
    { name: 'Moysés Katz', cnai: '37912', image: moysesImg },
  ];

  const linksToSimulate = [
    {
      name: 'Revisão da Avaliação e impugnação de valores de impostos municipais, estaduais e federais',
      link: 'https://www.banrisul.com.br/brz/link/brzw01xn_Simulador.aspx?css=bob&secao_id=1083',
    },
    {
      name: 'Compra e venda',
      extra: 'Renovação de aluguéis',
      link: 'http://www8.caixa.gov.br/siopiinternet-web/simulaOperacaoInternet.do?method=inicializarCasoUso',
    },
    {
      name: 'Em processos judiciais, como por exemplo, inventário, partilha e divórcio',
      link: 'https://www42.bb.com.br/portalbb/imobiliario/creditoimobiliario/simular,802,2250,2250.bbx?_ga=2.259936393.2038038905.1545912609-1743447006.1541415798',
    },
    {
      name: 'Seguro',
      link: 'https://www.webcasas.com.br/webcasas/?headerandfooter/#/dados-pessoais',
    },
    {
      name: 'Dação em pagamento e leilão',
      link: 'https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/simulador/',
    },
    {
      name: 'Atualização do valor de mercado dos ativos de uma empresa',
      link: 'https://google.com.br',
    },
  ];

  const [status, setStatus] = useState();

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
    agreed: true,
  };

  function sendContact(values) {
    Api.sendContactForm(values, setStatus);
  }

  return (
    <div>
      <WhatsappStation />
      <Header />
      <Navbar />
      <PageInfos currentPageName="Solicite uma Avaliação" />
      <div className="contact-area pd-top-responsive">
        <div className="container">
          <div className="row" style={{ rowGap: '3rem' }}>
            <div className="col-lg-8">
              <div className="my-container-example">
                <p>
                  A Moysés Imóveis realiza avaliações de imóveis urbanos e
                  rurais na cidade de Pelotas e região, com a finalidade de
                  precificar o bem de acordo com o mercado utilizando
                  principalmente o método comparativo e de acordo com a norma
                  NBR 14.653. Assim compreendendo a necessidade do solicitante
                  para garantir transparência e a adequada precificação do
                  imóvel.
                </p>
                <p>Você sabe quando deve solicitar avaliação de um imóvel?</p>
              </div>
              <div className="links-to-simulate-container">
                {linksToSimulate.map((link, index) => {
                  return (
                    <div
                      key={index}
                      className="links-to-simulate-container__link"
                    >
                      <h1>{'0' + (index + 1)}</h1>
                      <h3>
                        {link.name} <br /> {link.extra && link.extra}
                      </h3>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-4 form-wrapper">
              <Formik
                initialValues={initialValues}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Campo obrigatório';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'E-mail inválido';
                  }

                  if (!values.name) {
                    errors.name = 'Campo obrigatório';
                  }

                  if (!values.phone) {
                    errors.phone = 'Campo obrigatório';
                  } else if (
                    !/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/g.test(values.phone)
                  ) {
                    errors.phone = 'Número inválido';
                  }

                  if (!values.agreed) {
                    errors.agreed = 'Campo obrigatório';
                  }

                  return errors;
                }}
                onSubmit={(values, { resetForm }) => {
                  sendContact({
                    subject: `Solicitar Avaliação — ${values.name} `,
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    message: values.message,
                  });
                  resetForm();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      className="sell-property-form__contact"
                    >
                      {status === undefined ? (
                        <div className="contact">
                          <div>
                            <FastField
                              component={TextField}
                              className="test-input"
                              variant="outlined"
                              name="name"
                              label="Nome*"
                            />
                          </div>
                          <div className="mt-18">
                            <InputMask
                              mask="(99) 99999-9999"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {(inputProps) => (
                                <FastField
                                  component={TextField}
                                  className="test-input"
                                  variant="outlined"
                                  name="phone"
                                  label="Seu Telefone*"
                                  inputProps={{ ...inputProps }}
                                />
                              )}
                            </InputMask>
                          </div>
                          <div className="mt-18">
                            <FastField
                              component={TextField}
                              className="test-input"
                              type="email"
                              variant="outlined"
                              name="email"
                              label="Seu Email*"
                            />
                          </div>

                          <div className="mt-18">
                            <FastField
                              component={TextField}
                              className="test-input"
                              multiline
                              type="text"
                              variant="outlined"
                              name="message"
                              label="Mensagem"
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              classes="checkbox-label"
                              control={
                                <Checkbox
                                  name="agreed"
                                  checked={values.agreed}
                                  onChange={handleChange}
                                  sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: 'var(--main-color-one)',
                                    },
                                  }}
                                />
                              }
                              label="Dados utilizados exclusivamente pela Moysés Imóveis para
              responder seu contato e envio de informações do mercado
              imobiliário."
                            />
                            {errors.agreed && (
                              <p className="input-error">{errors.agreed}</p>
                            )}
                          </div>

                          <button
                            type="submit"
                            className="btn btn-yellow mt-18 sell-property-form__contact__button"
                          >
                            SOLICITAR CONTATO
                          </button>
                          <p>
                            {touched.name &&
                              !values.name &&
                              touched.phone &&
                              !values.phone &&
                              touched.email &&
                              !values.email &&
                              touched.agreed &&
                              !values.agreed &&
                              'Preencha os campos obrigatórios'}
                          </p>
                        </div>
                      ) : status >= 200 && status < 300 ? (
                        <div className="status-wrapper">
                          <CheckCircleIcon />
                          <p>Formulário enviado!</p>
                        </div>
                      ) : (
                        <div className="status-wrapper">
                          <ErrorIcon />
                          <p>Ocorreu algum erro!</p>
                          <p>Tente novamente mais tarde.</p>
                        </div>
                      )}
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <h2
            style={{
              color: '#0E0E0E',
              lineHeight: '120%',
              fontSize: '2.5rem',
              fontWeight: 'normal',
              width: 'fit-content',
              margin: '5rem auto 80px',
              textAlign: 'center',
            }}
          >
            Conheça nossos avaliadores registrados no Cadastro Nacional de
            Avaliadores de Imóveis
          </h2>
          <div className="row mt-3 center-items">
            {evaluators?.map(({ image, name, cnai }, i) => {
              return (
                <div key={i} className="col-lg-4 col-sm-6 col-11">
                  <div className="single-team">
                    <div>
                      <div alt="team" className="image-wrapper">
                        <img src={image} className="image" />
                      </div>
                    </div>
                    <div className="my-team-details team-details">
                      <h4>{name}</h4>
                      <span>CNAI: {cnai}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ margin: '5rem 0' }}>
            <h2
              style={{
                color: '#0E0E0E',
                lineHeight: '120%',
                fontSize: '2.5rem',
                fontWeight: 'normal',
                width: 'fit-content',
                margin: '3rem auto 80px',
                textAlign: 'center',
              }}
            >
              Entre em contato
            </h2>
            <div className="row my-contact-info">
              <div className="col-xl-3 col-sm-6 my-contact-info__data">
                <div className="single-contact-info">
                  <p>
                    <i className="fa fa-phone" />
                    <strong>Telefone:</strong>
                  </p>
                  <h5 style={{ color: '#999999' }}>(53) 98160-0126</h5>
                </div>
              </div>

              <div className="col-xl-3 col-sm-6 my-contact-info__data">
                <div className="single-contact-info">
                  <p>
                    <i className="fa fa-envelope" />
                    <strong>E-mail</strong>
                  </p>
                  <h5 style={{ color: '#999999' }}>
                    moysesimoveis@yahoo.com.br
                  </h5>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 my-contact-info__data">
                <div className="single-contact-info">
                  <p>
                    <i className="fa fa-building" />
                    <strong>Horário de Atendimento</strong>
                  </p>
                  <h5 style={{ color: '#999999' }}>
                    Segunda à Sexta: 8h-12h e 14h-18h
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

const TextField = styled(MuiTextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: 'var(--main-color-one)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d4d4',
    },
    '&:hover fieldset': {
      borderColor: 'var(--main-color-one)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--main-color-one)',
    },
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const FormControlLabel = styled(MuiFormControlLabel)({
  marginTop: '1rem',
  marginRight: '0',

  '.MuiFormControlLabel-label': {
    fontSize: '.7rem',
  },
});

export default EvaluationPage;
