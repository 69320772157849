import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/global-components/Header/Header.jsx';
import Navbar from '../components/global-components/Navbar/Navbar';
import Banner from '../components/section-components/Banner/Banner.jsx';
import Service from '../components/section-components/ServiceComponent/Service.jsx';
import FeaturedProperties from '../components/section-components/FeaturedProperties/FeaturedProperties.jsx';
import PropertiesByNeighborhood from '../components/section-components/PropertiesByNeighborhood/PropertiesByNeighborhood.js';
import WhyChooseUs from '../components/section-components/WhyChooseUs/WhyChooseUs.jsx';
import OurSocialMedias from '../components/section-components/our-social-medias';
import Footer from '../components/global-components/Footer/Footer.jsx';

import WhatsappStation from '../components/global-components/WhatsappStation/WhatsappStation.jsx';
import BackToTop from './../components/global-components/BackToTop/BackToTop';

const Home = () => {
  const logo = 'https://cms.moysesimoveis.com.br/upload/logo-98239781.png';
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Moysés Imóveis" />
        <meta property="og:image" content={logo} />
        <meta
          property="og:description"
          content="Comprometimento, informação, lisura, segurança, seriedade e tempo são valores que pautam o nosso dia a dia na Moysés Imóveis."
        />
        <meta property="og:url" content="https://www.moysesimoveis.com.br/" />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content="Moysés Imóveis" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={logo} />
      </Helmet>
      <div>
        <WhatsappStation />
        <BackToTop />
        <Header />
        <Navbar />
        <Banner />
        <Service />
        <FeaturedProperties />
        <PropertiesByNeighborhood />
        <WhyChooseUs />
        <OurSocialMedias />
        <Footer />
      </div>
    </>
  );
};

export default Home;
