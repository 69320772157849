import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { Api } from '../../../Api';
import env from './../../../.env.json';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './PopularPost.scss';

const settings = {
  className: 'slider',
  variableWidth: false,
  arrows: false,
  dots: false,
  infinite: false,
  autoplay: false,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },

    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const PopularPost = ({ search, setSearch, setSelectedCategory }) => {
  const [showSearch, setShowSearch] = useState(false);

  const [categories, setCategories] = useState();

  const postImage = env.postImage;

  const [popularPosts, setPopularPosts] = useState();
  let imagealt = 'image';

  function handleShowSearch() {
    showSearch ? setShowSearch(false) : setShowSearch(true);
  }

  function getPostsCategories() {
    Api.getPostsCategories().then((data) => {
      setCategories(data);
    });
  }

  function getPopularPosts() {
    let limit = 8;
    Api.getPopularPosts(limit).then(({ posts }) => {
      setPopularPosts(posts);
    });
  }

  useLayoutEffect(() => {
    getPopularPosts();
    getPostsCategories();
  }, []);

  return (
    <div className="main-container">
      <div className="popular-post-area">
        <div className="container">
          <div className="post-and-search">
            <div className="news-search-btn" onClick={() => handleShowSearch()}>
              <i className="fa fa-search" />
            </div>
            <form
              className={`news-search-box ${
                showSearch && `news-search-box-show`
              }`}
              onSubmit={(ev) => ev.preventDefault()}
            >
              {showSearch && (
                <>
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder="Busque um termo"
                  />
                  <select
                    className="blog-search-select"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">Categorias</option>
                    {categories.map((category, i) => (
                      <option key={i} value={category.id}>
                        {category.title}
                      </option>
                    ))}
                  </select>
                  <button type="button" onClick={() => handleShowSearch()}>
                    <i className="fa fa-times" />
                  </button>
                </>
              )}
            </form>
            <h6
              className="mb-3 popular-post-title"
              style={{ color: 'var(--main-color-one)' }}
            >
              Posts Populares
            </h6>
            <Slider {...settings}>
              {popularPosts?.map((item) => {
                const date = new Date(item?.date).toLocaleString('pt-BR', {
                  dateStyle: 'short',
                });
                return (
                  <div key={item.id} className="item">
                    <Link
                      to={`/blog/post/${item?.id}/${item?.slug}`}
                      className="media single-popular-post"
                    >
                      <div className="media-left">
                        <img src={postImage + item?.image} alt={imagealt} />
                      </div>
                      <div className="media-body">
                        <h6 style={{ color: 'var(--main-color-one)' }}>
                          {item.title}
                        </h6>
                        <span>{date}</span>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularPost;
