import React, { Component } from "react";
import { Link } from "react-router-dom";

import CampingImage from "./../../../assets/images/camping.svg";
import StarImage from "./../../../assets/images/star.svg";
import MedalImage from "./../../../assets/images/medal.svg";

import "./WhyChooseUs.scss";

class WhyChooseUs extends Component {
  render() {
    let reasons = [
      {
        image: CampingImage,
        title: "Foco na venda de imóveis",
        url: "#",
        content:
          "Com um portfólio que inclui opções de lançamentos imobiliários, imóveis residenciais e comerciais.",
        class: "",
      },
      {
        image: StarImage,
        title: "Tenha Exclusividade",
        url: "#",
        content:
          "Trabalhamos exclusivamente com imóveis localizados na cidade de Pelotas, com diversas oportunidades nos bairros da cidade.",
        class: "offset-xl-1",
      },
      {
        image: MedalImage,
        title: "Melhor Atendimento",
        url: "#",
        content:
          "Acreditamos que o atendimento e a transparência nas relações são fundamentais para a realização de bons negócios",
        class: "offset-xl-1",
      },
    ];

    return (
      <div className="why-choose-us-area bg-gray pd-top-90 pd-bottom-60">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">Por que a Moysés Imóveis?</h2>
          </div>
          <div className="row justify-content-center">
            <p className="description">
              Comprometimento, informação, lisura, segurança, seriedade e tempo
              são valores que pautam o nosso dia a dia na Moysés Imóveis. Aqui
              você encontra pessoas comprometidas em proporcionar soluções
              imobiliárias seguras e ágeis que visam à realização pessoal e
              profissional de nossos clientes.
            </p>
            {reasons.map((reason, i) => (
              <div
                key={i}
                className={"col-xl-3 col-lg-4 col-sm-6 " + reason.class}
              >
                <div className="single-intro text-lg-left text-center">
                  <div className="thumb">
                    <img src={reason.image} alt={reason.title} />
                  </div>
                  <div className="details">
                    <h4 className="title reason-title">{reason.title}</h4>
                    <p className="reason-description">{reason.content}</p>
                  </div>
                </div>
              </div>
            ))}

            <Link className="navigation-phrase" to="/sobre-nos">
              Saiba mais sobre a empresa{" "}
              <i
                className="la la-long-arrow-right"
                style={{ color: "var(--main-color-one)" }}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyChooseUs;
