import React from 'react';
import Modal from 'react-modal';

import CloseIcon from './../../../assets/icons/close-icon.svg';

import './FilterModal.scss';

function FilterModal({
  modalIsOpen,
  setModalIsOpen,
  advancedFilterValues,
  setAdvancedFilterValues,
}) {
  const options = [
    [
      { id: 1, label: 'Churrasqueira privativa' },
      { id: 2, label: 'Dependência completa' },
      { id: 3, label: 'Elevador' },
      { id: 4, label: 'Escritório' },
      { id: 5, label: 'Lareira' },
      { id: 6, label: 'Sacada' },
      { id: 61, label: 'Térreo' },
      { id: 62, label: 'WC auxiliar' },
      { id: 58, label: 'Varanda' },
    ],
    [
      { id: 7, label: 'Academia' },
      { id: 8, label: 'Churrasqueira' },
      { id: 9, label: 'Espaço gourmet' },
      { id: 10, label: 'Jardim' },
      { id: 11, label: 'Pátio' },
      { id: 12, label: 'Piscina' },
      { id: 13, label: 'Playground' },
      { id: 14, label: 'Quadra de tênis' },
      { id: 15, label: 'Quadra poliesportiva' },
      { id: 16, label: 'Salão de festas' },
      { id: 17, label: 'Salão de jogos' },
    ],
    [
      { id: 18, label: 'Circuito de segurança' },
      { id: 19, label: 'Condomínio fechado' },
      { id: 20, label: 'Sistema de alarme' },
      { id: 21, label: 'Portaria 12h' },
      { id: 22, label: 'Portaria 24h' },
    ],
  ];

  const customStyles = {
    content: {
      height: '80%',
      width: '90%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '24px 30px',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#realdeal');

  function applyFilters() {
    setModalIsOpen(false);
  }

  function addOrRemove(item) {
    const exists = advancedFilterValues.includes(item.id);

    if (exists) {
      setAdvancedFilterValues((oldState) =>
        oldState.filter((id) => id !== item.id)
      );
    } else {
      setAdvancedFilterValues((oldState) => [...oldState, item.id]);
    }
  }

  function sortItems(a, b) {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    // a must be equal to b
    return 0;
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={customStyles}
    >
      <div className="row-container">
        <h2 className="modal-title">Filtros avançados</h2>
        <img
          className="modal-close-icon"
          src={CloseIcon}
          alt="close icon"
          onClick={() => setModalIsOpen(false)}
        />
      </div>
      <h2 className="modal-subtitle">Comodidades</h2>
      <div className="column-container">
        {options[0].sort(sortItems).map((comfort, index) => {
          const marginTop = [0, 1, 2].indexOf(index) === -1;

          return (
            <div
              key={comfort.label + index}
              className={`column-container__item ${marginTop ? 'mt-20' : ''}`}
            >
              <input
                className="column-container__item__checkbox"
                type="checkbox"
                checked={
                  advancedFilterValues.findIndex((el) => el === comfort.id) > -1
                }
                onChange={() => addOrRemove(comfort)}
              />
              <label className="column-container__item__label">
                {comfort.label}
              </label>
            </div>
          );
        })}
      </div>

      <h2 className="modal-subtitle">Estruturas de Lazer</h2>
      <div className="column-container">
        {options[1].sort(sortItems).map((laze, index) => {
          const marginTop = [0, 1, 2].indexOf(index) === -1;

          return (
            <div
              key={laze.label + index}
              className={`column-container__item ${marginTop ? 'mt-20' : ''}`}
            >
              <input
                className="column-container__item__checkbox"
                type="checkbox"
                checked={
                  advancedFilterValues.findIndex((el) => el === laze.id) > -1
                }
                onChange={() => addOrRemove(laze)}
              />
              <label className="column-container__item__label">
                {laze.label}
              </label>
            </div>
          );
        })}
      </div>

      <h2 className="modal-subtitle">Segurança</h2>
      <div className="column-container">
        {options[2].sort(sortItems).map((security, index) => {
          const marginTop = [0, 1, 2].indexOf(index) === -1;

          return (
            <div
              key={security.label + index}
              className={`column-container__item ${marginTop ? 'mt-20' : ''}`}
            >
              <input
                className="column-container__item__checkbox"
                type="checkbox"
                checked={
                  advancedFilterValues.findIndex((el) => el === security.id) >
                  -1
                }
                onChange={() => addOrRemove(security)}
              />
              <label className="column-container__item__label">
                {security.label}
              </label>
            </div>
          );
        })}
      </div>

      <button className="modal-submit-button" onClick={() => applyFilters()}>
        Filtrar
      </button>
    </Modal>
  );
}

export { FilterModal };
