import TurndownService from 'turndown';

const turndownService = new TurndownService();

turndownService.addRule('remove', {
  filter: ['html', 'body', 'span', 'div', 'em', 'i', 'b', 'a'],
  replacement: function (innerHTML) {
    return innerHTML;
  },
});

turndownService.addRule('remove2', {
  filter: ['head', 'script', 'style'],
  replacement: function () {
    return '';
  },
});

export const htmlToString = (richtext) => {
  return turndownService
    .turndown(richtext)
    .replace(/\\/g, '')
    .replace(/\*\*/g, '');
};
