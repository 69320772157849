import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Logo from './../../../assets/images/moyses-logo.png';
import heartIcon from './../../../assets/icons/heart-icon.svg';

import './Navbar.scss';

function Navbar() {
	const location = useLocation();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const pages = [
		{ link: '/', label: 'Home' },
		{ link: '/imoveis?category=residencial', label: 'Imóveis Residenciais' },
		{ link: '/imoveis?category=comercial', label: 'Imóveis Comerciais' },
		{ link: '/fale-conosco', label: 'Fale conosco' },
		{ link: '/blog', label: 'Blog + que Imóveis' },
	];

	useEffect(() => {
		const htmlElement = document.querySelector('html');
		if (isMenuOpen) {
			htmlElement.style.overflow = 'hidden';
		} else {
			htmlElement.style.overflow = 'visible';
		}
	}, [isMenuOpen]);

	return (
		<div className={`navbar ${isMenuOpen ? 'mobile-menu' : ''}`}>
			<div
				className={`navbar__navbar-container ${
					isMenuOpen ? 'mobile-menu' : ''
				}`}
			>
				<div className='navbar__navbar-container__logo-container'>
					<Link to='/'>
						<img className='logo' src={isMenuOpen ? Logo : Logo} alt='logo' />
					</Link>
				</div>
				<nav
					className={`navbar__navbar-container__navigation ${
						isMenuOpen ? 'mobile-menu' : ''
					}`}
				>
					<ul className='navbar__navbar-container__navigation__list'>
						{pages.map((page) => {
							return page.link === '/blog' ? (
								<li
									key={page.link}
									className={location.pathname === page.link ? 'active' : ''}
									onClick={() => setIsMenuOpen(false)}
								>
									<Link to={page.link}>{page.label}</Link>
								</li>
							) : (
								<li
									key={page.link}
									className={
										location.pathname + location.search === page.link
											? 'active'
											: ''
									}
									onClick={() => setIsMenuOpen(false)}
								>
									{[
										'/imoveis?category=residencial',
										'/imoveis?category=comercial',
									].indexOf(page.link) > -1 ? (
										<Link to={page.link}>{page.label}</Link>
									) : (
										<Link to={page.link}>{page.label}</Link>
									)}
								</li>
							);
						})}
						{isMenuOpen && (
							<li className='heart-icon'>
								<Link to='/favoritos'>
									<img src={heartIcon} alt='heart icon' />
								</Link>
							</li>
						)}
					</ul>

					{isMenuOpen && (
						<>
							<Link
								to='/venda-seu-imovel'
								className='navbar__navbar-container__navigation__button'
							>
								Venda seu imóvel
							</Link>
							<div className='navbar__navbar-container__navigation__footer'>
								<a
									className='navbar__navbar-container__navigation__footer__infos'
									href='tel:53 3028-5706'
								>
									<small>Fale conosco</small>
									<span>(53) 3028-5706</span>
								</a>
								<a
									className='navbar__navbar-container__navigation__footer__infos'
									href='https://api.whatsapp.com/send?phone=5553981600126'
									target='_blank'
									rel='noopener noreferrer'
								>
									<small style={{ visibility: 'hidden' }}>Plantão</small>
									<span>(53) 98160-0126</span>
								</a>
							</div>
						</>
					)}
				</nav>
				<div
					className={`navbar__navbar-container__mobile-menu ${
						isMenuOpen ? 'opened' : ''
					}`}
					onClick={() => setIsMenuOpen(!isMenuOpen)}
				>
					<div className='one' />
					<div className='two' />
					<div className='three' />
				</div>
			</div>
		</div>
	);
}

export default Navbar;
