import React, { useState, useEffect } from 'react';
import { Api } from '../../Api';
import parse from 'html-react-parser';

const Process = () => {
  const [faqItems, setFaqItems] = useState(null);

  function getFaq() {
    Api.getFaq().then((data) => {
      let items = data
        .sort((a, b) => {
          return a.id - b.id;
        })
        .map((item) => ({
          id: item.id,
          question: item.question,
          answer: item.answer,
        }));

      setFaqItems(items);
    });
  }

  useEffect(() => {
    getFaq();
  }, []);

  return (
    <div className="faq-area pd-top-100 pd-bottom-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-5 mb-lg-0">
            <div className="section-title">
              <h2 style={{ color: 'var(--main-color-one)' }} className="title">
                PERGUNTAS? NÓS RESPODEMOS
              </h2>
              <p>
                Ter dúvidas é normal, todos nós temos! Então com a intenção de
                ajudar, nós listamos as perguntas mais frequentes que recebemos
                de nossos clientes. Sua dúvida não foi respondida? Entre em
                contato conosco para maiores informações.
              </p>
            </div>
            <div className="accordion" id="accordion">
              {faqItems?.map((item, i) => (
                <div key={i} className="single-accordion card">
                  <div className="card-header" id={'headingOne' + i}>
                    <h2 className="mb-0">
                      <button
                        style={{ color: 'var(--main-color-one)' }}
                        className="btn-link"
                        type="button"
                        data-toggle="collapse"
                        data-target={'#collapseOne' + i}
                        aria-expanded="true"
                        aria-controls={'collapseOne' + i}
                      >
                        {item.question}
                      </button>
                    </h2>
                  </div>
                  <div
                    id={'collapseOne' + i}
                    className={i === 0 ? 'show' : 'collapse'}
                    aria-labelledby={'headingOne' + i}
                    data-parent="#accordion"
                  >
                    <div className="card-body">{parse(item.answer)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
