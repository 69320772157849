import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Api } from './../../../Api';

import './Service.scss';

const Service = () => {
  const [propertiesQuantity, setPropertiesQuantity] = useState([]);
  const propertyNameMapper = {
    house: 'Casas',
    apartment: 'Apartamentos',
    land: 'Terrenos',
    condominium: 'Condomínios',
  };
  const searchMappers = {
    house: 'casa',
    apartment: 'apartamento,cobertura,kitnet,loft,studio',
    condominium: 'casa em condominio,terreno em condomínio',
    land: 'terreno',
  };

  function getAndSetPropertiesQuantity() {
    Api.getPropertiesQuantity().then((data) => {
      setPropertiesQuantity(Object.entries(data));
    });
  }

  useEffect(() => {
    getAndSetPropertiesQuantity();
  }, []);

  const settings = {
    className: 'slider',
    arrows: false,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.25,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
        },
      },

      {
        breakpoint: 792,
        settings: {
          slidesToShow: 2.25,
        },
      },

      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2.5,
        },
      },

      {
        breakpoint: 1032,
        settings: {
          slidesToShow: 3.25,
        },
      },
    ],
  };

  return (
    <div className="slick-container">
      <Slider {...settings}>
        {propertiesQuantity.map((item, i) => {
          return (
            <div key={i}>
              <div className="single-service">
                <div className="details readeal-top">
                  <h4>{propertyNameMapper[item[1].type]}</h4>
                </div>
                <div>
                  <h2>{item[1].quantity}</h2>
                  <p>Ofertas disponíveis</p>
                </div>
                <Link
                  className="readmore-btn"
                  to={`/imoveis?type=${searchMappers[item[1].type]}`}
                >
                  Confira agora
                  <i className="la la-arrow-right" />
                </Link>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Service;
