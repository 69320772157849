export const propertyTypes = [
  {
    value: 'comercial',
    label: 'Comercial',
    subTypes: [
      {
        value: 'casa comercial',
        label: 'Casa Comercial',
      },
      {
        value: 'sala comercial',
        label: 'Sala Comercial',
      },
      {
        value: 'conjunto comercial',
        label: 'Conjunto Comercial',
      },
      {
        value: 'galpao/deposito/armazem',
        label: 'Galpão/depósito/armazém',
      },
      {
        value: 'residencial/comercial',
        label: 'Residencial/comercial',
      },
      {
        value: 'Prédio comercial',
        label: 'Prédio Comercial',
      },
      {
        value: 'terreno',
        label: 'Terreno',
      },
      {
        value: 'terreno em condomínio',
        label: 'Terreno em condomínio',
      },
      {
        value: 'garagem',
        label: 'Garagem',
      },
      {
        value: 'loja',
        label: 'Loja',
      },
    ],
  },
  {
    value: 'residencial',
    label: 'Residencial',
    subTypes: [
      {
        value: 'apartamento',
        label: 'Apartamento',
      },
      {
        value: 'cobertura',
        label: 'Cobertura',
      },
      {
        value: 'casa',
        label: 'Casa',
      },
      {
        value: 'casa em condominio',
        label: 'Casa em condomínio',
      },
      {
        value: 'kitnet',
        label: 'Kitnet',
      },
      {
        value: 'loft',
        label: 'Loft',
      },
      {
        value: 'studio',
        label: 'Studio',
      },
      {
        value: 'terreno',
        label: 'Terreno',
      },
      {
        value: 'terreno em condominio',
        label: 'Terreno em condomínio',
      },
      {
        value: 'garagem',
        label: 'Garagem',
      },
    ],
  },
  {
    value: 'rural',
    label: 'Rural',
    subTypes: [
      {
        value: 'fazenda',
        label: 'Fazenda',
      },
      {
        value: 'campo',
        label: 'Campo',
      },
      {
        value: 'sitio',
        label: 'Sítio',
      },
    ],
  },
];
