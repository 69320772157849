import React from 'react';
import { Link } from 'react-router-dom';

import ArealImage from './../../../assets/images/areal.jpg';
import BaronesaImage from './../../../assets/images/baronesa.jpg';
import CentroImage from './../../../assets/images/centro.jpg';
import ZonaNorteImage from './../../../assets/images/zona-norte.jpg';
import LaranjalImage from './../../../assets/images/laranjal.jpg';
import SaoGoncaloImage from './../../../assets/images/sao-goncalo.jpg';
import RecantoImage from './../../../assets/images/recanto.jpg';

import './PropertiesByNeighborhood.scss';

function PropertiesByNeighborhood() {
  const propertiesByNeighborhood = [
    {
      neighborhood: 'Areal',
      image: ArealImage,
      url: '/imoveis?district=2',
      class: 'col-lg-2 col-sm-6',
      sectionclass: 'sc-one',
      imgClass: 'areal',
    },
    {
      neighborhood: 'Baronesa',
      image: BaronesaImage,
      url: '/imoveis?district=14',
      class: 'col-lg-2 col-sm-6',
      sectionclass: 'sc-two',
      imgClass: 'baronesa',
    },
    {
      neighborhood: 'Zona Norte',
      image: ZonaNorteImage,
      url: '/imoveis?district=43',
      class: 'col-lg-5 col-sm-6',
      sectionclass: '',
      imgClass: 'zona-norte',
    },
    {
      neighborhood: 'Centro',
      image: CentroImage,
      url: '/imoveis?district=8',
      class: 'col-lg-4 col-sm-6',
      sectionclass: '',
      imgClass: 'centro',
    },
    {
      neighborhood: 'Laranjal',
      image: LaranjalImage,
      url: '/imoveis?district=23',
      class: 'col-lg-3 col-sm-6',
      sectionclass: 'sc-three',
      imgClass: 'laranjal',
    },
    {
      neighborhood: 'São Gonçalo',
      image: SaoGoncaloImage,
      url: '/imoveis?district=35',
      class: 'col-lg-4 col-sm-6',
      sectionclass: '',
      imgClass: 'sao-goncalo',
    },
    {
      neighborhood: 'Recanto',
      image: RecantoImage,
      url: '/imoveis?district=33',
      class: 'col-lg-3 col-sm-6 recanto-wrapper',
      sectionclass: '',
      imgClass: 'recanto',
    },
  ];

  return (
    <div className="city-intro-area pd-bottom-70">
      {/* city area start */}
      <div className="city-area pd-top-92">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title" style={{ color: 'var(--heading-color)' }}>
              Imóveis por bairro
            </h2>
          </div>
          <div className="city-filter-area row">
            <div className="city-sizer col-1" />
            {propertiesByNeighborhood.map((property, i) => (
              <div key={i} className={'rld-city-item ' + property.class}>
                <Link to={property.url}>
                  <div className={'single-city ' + property.sectionclass}>
                    <div className="sc-overlay" />
                    <div className="thumb">
                      <img
                        className={property.imgClass}
                        src={property.image}
                        alt={property.neighborhood}
                      />
                    </div>
                    <div className="details">
                      <h5 className="title" style={{ marginBottom: '10%' }}>
                        <span style={{ color: '#fff' }}>
                          {property.neighborhood}
                        </span>
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* city area end */}
    </div>
  );
}

export default PropertiesByNeighborhood;
