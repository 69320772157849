import React from 'react';

import { ReactComponent as HeartIcon } from './../../../assets/icons/heart-icon.svg';

import './FavoriteHeart.scss';

function FavoriteHeart({ handleClick, isActive, className }) {
  return (
    <div
      className={`favorite-heart ${className ? className : ''}`}
      onClick={handleClick}
    >
      <HeartIcon className={isActive ? 'active' : 'unactive'} />
    </div>
  );
}

export { FavoriteHeart };
