import React from 'react';

import Navbar from '../components/global-components/Navbar/Navbar';
import UserListSection from '../components/section-components/UserList/UserList';
import Footer from '../components/global-components/Footer/Footer.jsx';
import Header from '../components/global-components/Header/Header';
import PageInfos from '../components/global-components/PageInfos/PageInfos';

const UsefulLinks = () => {
  return (
    <div>
      <Header />
      <Navbar />
      <PageInfos currentPageName="Links Úteis" />
      <UserListSection />
      <Footer />
    </div>
  );
};

export default UsefulLinks;
