import React, { useState } from 'react';
import { Formik, FastField } from 'formik';
import InputMask from 'react-input-mask';
import { TextField as MuiTextField } from 'formik-mui';

import { styled } from '@mui/material/styles';

import Header from '../../components/global-components/Header/Header';
import Navbar from '../../components/global-components/Navbar/Navbar';
import PageInfos from '../../components/global-components/PageInfos/PageInfos.jsx';
import OurSocialMedias from '../../components/section-components/our-social-medias';
import Footer from '../../components/global-components/Footer/Footer.jsx';
import WhatsappStation from '../../components/global-components/WhatsappStation/WhatsappStation.jsx';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

import './FinancingSimulation.scss';
import BankCards from '../../components/section-components/BankCards/BankCards';
import { Api } from '../../Api';

const FinancingSimulation = () => {
  const [status, setStatus] = useState();

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
    agreed: true,
  };

  function sendContact(values) {
    Api.sendContactForm(values, setStatus);
  }

  return (
    <div>
      <Header />
      <Navbar />
      <PageInfos currentPageName="Simulador de Financiamento" />
      <div className="property-details-area">
        <div>
          <div className="container">
            <WhatsappStation />

            <div className="row sell-property-form pd-top-50">
              <div className="my-main-container">
                <div className="contact-description-container">
                  <h2 className="contact-title">
                    O QUE É E PARA QUE SERVE O SIMULADOR DE IMÓVEL
                  </h2>

                  <p className="contact-phrase">
                    O simulador de imóveis é uma ferramenta que permite ao
                    comprador verificar todas as possibilidades de financiamento
                    antes mesmo de realizar a aquisição do imóvel, conhecendo
                    assim melhor suas condições de compra.
                  </p>

                  <h2 className="contact-title title-margin-top">
                    COMO FAZER A SIMULAÇÃO
                  </h2>

                  <p className="contact-phrase margin-bottom">
                    Para ter acesso a uma simulação, basta acessar o site de uma
                    instituição bancária que ofereça créditos voltados para
                    financiamento imobiliário. Para te auxiliar a tomar a melhor
                    decisão de qual instituição escolher, selecionamos os
                    simuladores de financiamento imobiliário mais utilizados do
                    mercado.
                  </p>
                  <p className="contact-phrase">
                    Acesse um dos bancos abaixo para realizar a sua simulação
                    habitacional online.
                  </p>
                </div>
                <div className="container">
                  <div className="form-wrapper mb-5">
                    <BankCards />

                    <Formik
                      initialValues={initialValues}
                      validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                          errors.email = 'Campo obrigatório';
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = 'E-mail inválido';
                        }

                        if (!values.name) {
                          errors.name = 'Campo obrigatório';
                        }

                        if (!values.phone) {
                          errors.phone = 'Campo obrigatório';
                        } else if (
                          !/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/g.test(
                            values.phone
                          )
                        ) {
                          errors.phone = 'Número inválido';
                        }

                        if (!values.agreed) {
                          errors.agreed = 'Campo obrigatório';
                        }

                        return errors;
                      }}
                      onSubmit={(values, { resetForm }) => {
                        sendContact({
                          subject: `Contato — Financiamento — ${values.name} `,
                          name: values.name,
                          email: values.email,
                          phone: values.phone,
                          message: values.message,
                        });
                        resetForm();
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => {
                        return (
                          <form
                            onSubmit={handleSubmit}
                            className="sell-property-form__contact"
                          >
                            {status === undefined ? (
                              <div className="contact">
                                <div className="mt-18">
                                  <FastField
                                    component={TextField}
                                    className="test-input"
                                    variant="outlined"
                                    name="name"
                                    label="Nome*"
                                  />
                                </div>
                                <div className="mt-18">
                                  <InputMask
                                    mask="(99) 99999-9999"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    {(inputProps) => (
                                      <FastField
                                        component={TextField}
                                        className="test-input"
                                        variant="outlined"
                                        name="phone"
                                        label="Seu Telefone*"
                                        inputProps={{ ...inputProps }}
                                      />
                                    )}
                                  </InputMask>
                                </div>
                                <div className="mt-18">
                                  <FastField
                                    component={TextField}
                                    className="test-input"
                                    type="email"
                                    variant="outlined"
                                    name="email"
                                    label="Seu Email*"
                                  />
                                </div>

                                <div className="mt-18">
                                  <FastField
                                    component={TextField}
                                    className="test-input"
                                    multiline
                                    type="text"
                                    variant="outlined"
                                    name="message"
                                    label="Mensagem"
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    classes="checkbox-label"
                                    control={
                                      <Checkbox
                                        name="agreed"
                                        checked={values.agreed}
                                        onChange={handleChange}
                                        sx={{
                                          [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'var(--main-color-one)',
                                          },
                                        }}
                                      />
                                    }
                                    label="Dados utilizados exclusivamente pela Moysés Imóveis para
              responder seu contato e envio de informações do mercado
              imobiliário."
                                  />
                                  {errors.agreed && (
                                    <p className="input-error">
                                      {errors.agreed}
                                    </p>
                                  )}
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-yellow mt-18 sell-property-form__contact__button"
                                >
                                  SOLICITAR CONTATO
                                </button>
                                <p>
                                  {touched.name &&
                                    !values.name &&
                                    touched.phone &&
                                    !values.phone &&
                                    touched.email &&
                                    !values.email &&
                                    touched.agreed &&
                                    !values.agreed &&
                                    'Preencha os campos obrigatórios'}
                                </p>
                              </div>
                            ) : status >= 200 && status < 300 ? (
                              <div className="status-wrapper">
                                <CheckCircleIcon />
                                <p>Formulário enviado!</p>
                              </div>
                            ) : (
                              <div className="status-wrapper">
                                <ErrorIcon />
                                <p>Ocorreu algum erro!</p>
                                <p>Tente novamente mais tarde.</p>
                              </div>
                            )}
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row my-contact-info">
                  <div className="col-xl-3 col-sm-6 my-contact-info__data">
                    <div className="single-contact-info">
                      <p>
                        <i className="fa fa-phone" />
                        <strong>Plantão:</strong>
                      </p>
                      <h5 style={{ color: '#999999' }}>(53) 98116-1121</h5>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6 my-contact-info__data">
                    <div className="single-contact-info">
                      <p>
                        <i className="fa fa-fax" />
                        <strong>Telefone:</strong>
                      </p>
                      <h5 style={{ color: '#999999' }}>(53) 3028-5706</h5>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6 my-contact-info__data">
                    <div className="single-contact-info">
                      <p>
                        <i className="fa fa-envelope" />
                        <strong>Email</strong>
                      </p>
                      <h5 style={{ color: '#999999' }}>
                        moysesimoveis@yahoo.com.br
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurSocialMedias />
      <Footer />
    </div>
  );
};

const TextField = styled(MuiTextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: 'var(--main-color-one)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d4d4',
    },
    '&:hover fieldset': {
      borderColor: 'var(--main-color-one)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--main-color-one)',
    },
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const FormControlLabel = styled(MuiFormControlLabel)({
  marginTop: '1rem',
  marginRight: '0',

  '.MuiFormControlLabel-label': {
    fontSize: '.7rem',
  },
});

export default FinancingSimulation;
