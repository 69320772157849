import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { GoogleMap, useJsApiLoader, Circle } from '@react-google-maps/api';

import { Formik, FastField } from 'formik';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

import { TextField as MuiTextField } from 'formik-mui';

import InputMask from 'react-input-mask';
import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ErrorIcon from '@material-ui/icons/Error';
import ShareIcon from '@material-ui/icons/Share';
import MuiWhatsAppIcon from '@material-ui/icons/WhatsApp';
import MuiFacebookIcon from '@material-ui/icons/Facebook';
import MuiLinkedInIcon from '@material-ui/icons/LinkedIn';
import MuiTwitterIcon from '@material-ui/icons/Twitter';
import MuiInstagramIcon from '@material-ui/icons/Instagram';
import MuiLinkIcon from '@material-ui/icons/Link';

import env from './../../../.env.json';

import './PropertyDetailsSection.scss';
import ImageCarousel from '../ImageCarousel/ImageCarousel';

import { Api } from '../../../Api';
import { FavoriteHeart } from '../FavoriteHeart/FavoriteHeart';
import { useFavoriteProperties } from '../../../context/properties';

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from 'react-share';
import { CodeSharp } from '@material-ui/icons';

function PropertyDetailsSection({ property }) {
  const imagesUrlPrefix = env.imagesUrlPrefix;
  const [currentSection, setCurrentSection] = useState('pictures');
  const [slideImages, setSlideImages] = useState([]);
  const [status, setStatus] = useState();
  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  const { favoriteProperties, setFavoriteProperties } = useFavoriteProperties();

  const urlToShare = window.location.href;

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  };

  const options = {
    strokeColor: 'var(--main-color-two)',
    strokeOpacity: 0.5,
    strokeWeight: 1,
    fillColor: 'var(--main-color-two)',
    fillOpacity: 0.5,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 300,
    zIndex: 1,
  };

  const mapLocation = {
    lat: Number(property.address_latitude),
    lng: Number(property.address_longitude),
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC6LsA9sr67s3Ml46aM8qIeIg7nI8AlWms',
  });

  const currentUrl = window.location.href;

  const initialValues = {
    subject: '',
    name: '',
    email: '',
    phone: '',
    message: '',
    property_url: currentUrl || '',
    agreed: true,
  };

  const handleShareClick = (event) => {
    setShareAnchorEl(event.currentTarget);
  };

  const handleCloseShare = () => {
    setShareAnchorEl(null);
  };

  function copyText(entryText) {
    navigator.clipboard.writeText(entryText);
    handleCloseShare();
  }

  function sendContact(values) {
    Api.sendContactForm(values, setStatus);
  }

  function toggleFavorite(item) {
    const exist = favoriteProperties.find((x) => x.id === item.id);

    const propertyInformations = {
      id: item.id,
      title: item.title,
      price: item.price,
      code: item.code,
      bedroom: item.bedroom,
      garage_vacancy: item.garage_vacancy,
      bathroom: item.bathroom,
      image: imagesUrlPrefix + (item?.cover || item?.images[0]),
      control: item.control,
      description: item.description,
      condominium_title: item.condominium_title,
    };

    if (exist) {
      setFavoriteProperties((oldState) =>
        oldState.filter((x) => x.id !== item.id)
      );
    } else {
      setFavoriteProperties((oldState) => [...oldState, propertyInformations]);
    }
  }

  useEffect(() => {
    if (property.images) {
      // const sevenFirstImages = property.images.filter((_, index) => index <= 6);
      const slideImagesToSend = property.images.map((image) => {
        return { img: imagesUrlPrefix + image, alt: 'imobbile picture' };
      });
      setSlideImages(slideImagesToSend);
    }
  }, [imagesUrlPrefix, property.images]);

  function handleDescription() {
    let { type, address_district, address_street } = property;
    let description = [];

    type && description.push(type);
    address_district && description.push(address_district);
    address_street && description.push('Pelotas');

    return description.join(' - ');
  }

  return (
    <div className="property-details-area">
      <div className="bg-gray pd-top-responsive">
        <div className="container">
          <div className="property-infos">
            <div className="property-infos__description">
              <h2 className="title property-infos__description__name">
                {property.title ? property.title : ''}
                {property.condominium_title &&
                !Boolean(property.control.condominium_title)
                  ? ` - ${property.condominium_title}`
                  : ''}
              </h2>
              <div className="property-infos__description__wrapper">
                <p className="title property-infos__description__wrapper__location">
                  {handleDescription()}
                </p>
                <FavoriteHeart
                  className="favorite-icon"
                  isActive={
                    favoriteProperties.findIndex(
                      (el) => el.id === property.id
                    ) > -1
                  }
                  handleClick={() => toggleFavorite(property)}
                />
                <div className="share-button">
                  <IconButton aria-haspopup="true" onClick={handleShareClick}>
                    <ShareIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={shareAnchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    keepMounted
                    open={Boolean(shareAnchorEl)}
                    onClose={handleCloseShare}
                  >
                    <StyledMenuItem onClick={handleCloseShare}>
                      <WhatsappShareButton url={urlToShare}>
                        <WhatsAppIcon />
                      </WhatsappShareButton>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleCloseShare}>
                      <FacebookShareButton url={urlToShare}>
                        <FacebookIcon />
                      </FacebookShareButton>
                    </StyledMenuItem>

                    <StyledMenuItem onClick={handleCloseShare}>
                      <TwitterShareButton url={urlToShare}>
                        <TwitterIcon />
                      </TwitterShareButton>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleCloseShare}>
                      <LinkedinShareButton url={urlToShare}>
                        <LinkedInIcon />
                      </LinkedinShareButton>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => copyText(urlToShare)}>
                      <LinkIcon />
                    </StyledMenuItem>
                  </Menu>
                </div>
              </div>
            </div>

            <div className="property-infos__code">
              <span className="property-infos__code__info">
                Código {property.code ? property.code : ''}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-9 col-lg-8 custom-medias-container padding-top-responsive">
              <div className="custom-medias-container__options">
                <button
                  className={`custom-medias-container__options__button ${
                    currentSection === 'pictures' ? 'active' : ''
                  }`}
                  onClick={() => setCurrentSection('pictures')}
                >
                  Fotos
                </button>
                {property.video && (
                  <button
                    className={`custom-medias-container__options__button ${
                      currentSection === 'video' ? 'active' : ''
                    }`}
                    onClick={() => setCurrentSection('video')}
                  >
                    Vídeo
                  </button>
                )}
                {property.address_latitude && property.address_longitude && (
                  <button
                    className={`custom-medias-container__options__button ${
                      currentSection === 'map' ? 'active' : ''
                    }`}
                    onClick={() => setCurrentSection('map')}
                  >
                    Mapa
                  </button>
                )}
              </div>
              <div className="custom-medias-container__content">
                {currentSection === 'pictures' && slideImages.length && (
                  <div className="custom-medias-container__content__data">
                    <ImageCarousel slideImages={slideImages} />
                  </div>
                )}
                {currentSection === 'video' && property.video && (
                  <div className="custom-medias-container__content__data--video">
                    <ReactPlayer
                      controls
                      className="w-100"
                      height="100%"
                      url={property.video}
                      style={{
                        backgroundColor: '#999',
                        borderRadius: '0px 10px 10px 10px',
                      }}
                    />
                  </div>
                )}
                {currentSection === 'map' && isLoaded && (
                  <div className="custom-medias-container__content__data--map">
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={mapLocation}
                      zoom={15}
                    >
                      <Circle center={mapLocation} options={options} />
                    </GoogleMap>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 widget-wrapper">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                <Formik
                  validateOnBlur
                  initialValues={initialValues}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = 'Campo obrigatório';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = 'E-mail inválido';
                    }

                    if (!values.name) {
                      errors.name = 'Campo obrigatório';
                    }

                    if (!values.phone) {
                      errors.phone = 'Campo obrigatório';
                    } else if (
                      !/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/g.test(values.phone)
                    ) {
                      errors.phone = 'Número inválido';
                    }

                    if (!values.agreed) {
                      errors.agreed = 'Campo obrigatório';
                    }

                    return errors;
                  }}
                  onSubmit={(values, { resetForm }) => {
                    sendContact({
                      subject: `Tenho Interesse — Imóvel — ${values.name} `,
                      name: values.name,
                      email: values.email,
                      phone: values.phone,
                      message: values.message,
                      property_url: values.property_url,
                      property_code: property.code,
                      property_title: property.title,
                    });
                    resetForm();
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => {
                    return (
                      <form
                        onSubmit={handleSubmit}
                        className="property-details-form__contact"
                      >
                        {status === undefined ? (
                          <div className="contact">
                            <div className="mt-18">
                              <FastField
                                component={TextField}
                                className="test-input"
                                variant="outlined"
                                name="name"
                                values={values.name}
                                onChange={handleChange}
                                label="Nome*"
                              />
                            </div>
                            <div className="mt-18">
                              <InputMask
                                mask="(99) 99999-9999"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                {(inputProps) => (
                                  <FastField
                                    component={TextField}
                                    className="test-input"
                                    variant="outlined"
                                    name="phone"
                                    label="Seu Telefone*"
                                    inputProps={{ ...inputProps }}
                                  />
                                )}
                              </InputMask>
                            </div>
                            <div className="mt-18">
                              <FastField
                                component={TextField}
                                onChange={handleChange}
                                values={values.email}
                                className="test-input"
                                type="email"
                                variant="outlined"
                                name="email"
                                label="Seu Email*"
                              />
                            </div>

                            <div className="mt-18">
                              <FastField
                                component={TextField}
                                onChange={handleChange}
                                values={values.message}
                                className="test-input"
                                multiline
                                type="text"
                                variant="outlined"
                                name="message"
                                label="Mensagem"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                classes="checkbox-label"
                                control={
                                  <Checkbox
                                    name="agreed"
                                    checked={values.agreed}
                                    onChange={handleChange}
                                    sx={{
                                      [`&, &.${checkboxClasses.checked}`]: {
                                        color: 'var(--main-color-one)',
                                      },
                                    }}
                                  />
                                }
                                label="Dados utilizados exclusivamente pela Moysés Imóveis para
                  responder seu contato e envio de informações do mercado
                  imobiliário."
                              />
                              {errors.agreed && (
                                <p className="input-error">{errors.agreed}</p>
                              )}
                            </div>

                            <button
                              type="submit"
                              className="btn btn-yellow mt-18 sell-property-form__contact__button"
                            >
                              SOLICITAR CONTATO
                            </button>
                            <p className="input-error">
                              {touched.name &&
                                !values.name &&
                                touched.phone &&
                                !values.phone &&
                                touched.email &&
                                !values.email &&
                                touched.agreed &&
                                !values.agreed &&
                                'Preencha os campos obrigatórios'}
                            </p>
                          </div>
                        ) : status >= 200 && status < 300 ? (
                          <div className="status-wrapper">
                            <CheckCircleIcon />
                            <p>Formulário enviado!</p>
                          </div>
                        ) : (
                          <div className="status-wrapper">
                            <ErrorIcon />
                            <p>Ocorreu algum erro!</p>
                            <p>Tente novamente mais tarde.</p>
                          </div>
                        )}
                      </form>
                    );
                  }}
                </Formik>

                <div className="contact-info">
                  <h6 className="contact-info__title">
                    Informações de contato
                  </h6>
                  <div className="media">
                    <div className="media-left custom-icon">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="media-body">
                      <p className="contact-info__subtitle">Telefone</p>
                      <span className="contact-info__specific-info">
                        (53) 3028-5706
                      </span>
                    </div>
                  </div>
                  <div className="media mb-0">
                    <div className="media-left custom-icon">
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="media-body">
                      <p className="contact-info__subtitle">Email</p>
                      <span className="contact-info__specific-info">
                        moysesimoveis@yahoo.com.br
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledMenuItem = withStyles(() => ({
  root: {
    fontFamily: 'var(--heading-font)',
  },
}))(MenuItem);

const WhatsAppIcon = withStyles(() => ({
  root: {
    color: '#25D366',
  },
}))(MuiWhatsAppIcon);

const FacebookIcon = withStyles(() => ({
  root: {
    color: '#4267B2',
  },
}))(MuiFacebookIcon);

const LinkedInIcon = withStyles(() => ({
  root: {
    color: '#0077B5',
  },
}))(MuiLinkedInIcon);

const TwitterIcon = withStyles(() => ({
  root: {
    color: '#1DA1F2',
  },
}))(MuiTwitterIcon);

const InstagramIcon = withStyles(() => ({
  root: {
    color: '#E1306C',
  },
}))(MuiInstagramIcon);

const LinkIcon = withStyles(() => ({
  root: {},
}))(MuiLinkIcon);

const TextField = styled(MuiTextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: 'var(--main-color-one)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d4d4',
    },
    '&:hover fieldset': {
      borderColor: 'var(--main-color-one)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--main-color-one)',
    },
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const FormControlLabel = styled(MuiFormControlLabel)({
  marginTop: '1rem',
  marginRight: '0',

  '.MuiFormControlLabel-label': {
    fontSize: '.7rem',
  },
});

export default PropertyDetailsSection;
