import React, { useEffect, useState } from 'react';
import noImage from '../../../assets/images/no-image.png';

const ImageWithFallback = ({ src, fallback = noImage, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [error, setError] = useState(false);

  const onError = () => {
    setImgSrc(fallback);
    setError(true);
  };

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <img
      src={imgSrc ? imgSrc : fallback}
      onError={onError}
      style={{ height: error && 'auto' }}
      {...props}
    />
  );
};

export default ImageWithFallback;
