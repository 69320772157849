import React, { useState } from 'react';

import { Formik, FastField } from 'formik';
import InputMask from 'react-input-mask';
import { TextField as MuiTextField } from 'formik-mui';

import { styled } from '@mui/material/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

import Navbar from '../../components/global-components/Navbar/Navbar';
import Footer from '../../components/global-components/Footer/Footer.jsx';
import Header from '../../components/global-components/Header/Header';
import PageInfos from '../../components/global-components/PageInfos/PageInfos';
import OurSocialMedias from '../../components/section-components/our-social-medias';

import { Api } from '../../Api';

import WhatsappStation from '../../components/global-components/WhatsappStation/WhatsappStation.jsx';

import './Contact.scss';

const Contact = () => {
  const [status, setStatus] = useState();

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
    agreed: true,
  };

  function sendContact(values) {
    Api.sendContactForm(values, setStatus);
  }
  return (
    <div>
      <Header />
      <Navbar />
      <PageInfos currentPageName="Fale conosco" />
      <WhatsappStation />

      <div className="contact-area pd-top-responsive">
        <div className="container">
          <div className="contact-description-container">
            <h2 className="contact-title">PRECISA FALAR CONOSCO?</h2>
            <p className="contact-phrase">
              É só usar o formulário abaixo ou utilizar alguma das formas de
              contatos listadas para falar com nossa equipe. Seja ideia, crítica
              ou necessidade de serviços, teremos prazer em ouvir você!
            </p>
          </div>
          <div className="row top-wrapper">
            <div className="col-lg-8">
              <div className="contact-page-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3391.9420878935266!2d-52.340141984304395!3d-31.772065420726182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9511b5be51bc3315%3A0x4a051c6dd59a6596!2zTW95c8OpcyBJbcOzdmVpcw!5e0!3m2!1spt-BR!2sus!4v1649429271828!5m2!1spt-BR!2sus"
                  className="w-100"
                  style={{ border: 0 }}
                  allowfullscreen
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-4 form-wrapper">
              <Formik
                initialValues={initialValues}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Campo obrigatório';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'E-mail inválido';
                  }

                  if (!values.name) {
                    errors.name = 'Campo obrigatório';
                  }

                  if (!values.phone) {
                    errors.phone = 'Campo obrigatório';
                  } else if (
                    !/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/g.test(values.phone)
                  ) {
                    errors.phone = 'Número inválido';
                  }

                  if (!values.agreed) {
                    errors.agreed = 'Campo obrigatório';
                  }

                  return errors;
                }}
                onSubmit={(values, { resetForm }) => {
                  sendContact({
                    subject: `Fale Conosco — ${values.name}`,
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    message: values.message,
                  });
                  resetForm();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      className="sell-property-form__contact"
                    >
                      {status === undefined ? (
                        <div className="contact">
                          <div>
                            <FastField
                              component={TextField}
                              className="test-input"
                              variant="outlined"
                              name="name"
                              label="Nome*"
                            />
                          </div>
                          <div className="mt-18">
                            <InputMask
                              mask="(99) 99999-9999"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {(inputProps) => (
                                <FastField
                                  component={TextField}
                                  className="test-input"
                                  variant="outlined"
                                  name="phone"
                                  label="Seu Telefone*"
                                  inputProps={{ ...inputProps }}
                                />
                              )}
                            </InputMask>
                          </div>
                          <div className="mt-18">
                            <FastField
                              component={TextField}
                              className="test-input"
                              type="email"
                              variant="outlined"
                              name="email"
                              label="Seu Email*"
                            />
                          </div>

                          <div className="mt-18">
                            <FastField
                              component={TextField}
                              className="test-input"
                              multiline
                              type="text"
                              variant="outlined"
                              name="message"
                              label="Mensagem"
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              classes="checkbox-label"
                              control={
                                <Checkbox
                                  name="agreed"
                                  checked={values.agreed}
                                  onChange={handleChange}
                                  sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: 'var(--main-color-one)',
                                    },
                                  }}
                                />
                              }
                              label="Dados utilizados exclusivamente pela Moysés Imóveis para
              responder seu contato e envio de informações do mercado
              imobiliário."
                            />
                            {errors.agreed && (
                              <p className="input-error">{errors.agreed}</p>
                            )}
                          </div>

                          <button
                            type="submit"
                            className="btn btn-yellow mt-18 sell-property-form__contact__button"
                          >
                            SOLICITAR CONTATO
                          </button>
                          <p>
                            {touched.name &&
                              !values.name &&
                              touched.phone &&
                              !values.phone &&
                              touched.email &&
                              !values.email &&
                              touched.agreed &&
                              !values.agreed &&
                              'Preencha os campos obrigatórios'}
                          </p>
                        </div>
                      ) : status >= 200 && status < 300 ? (
                        <div className="status-wrapper">
                          <CheckCircleIcon />
                          <p>Formulário enviado!</p>
                        </div>
                      ) : (
                        <div className="status-wrapper">
                          <ErrorIcon />
                          <p>Ocorreu algum erro!</p>
                          <p>Tente novamente mais tarde.</p>
                        </div>
                      )}
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <div className="row my-contact-info">
            <div className="col-xl-3 col-sm-6 my-contact-info__data">
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-phone" />
                  <strong style={{ color: '#999999' }}>Plantão</strong>
                </p>
                <a href="tel:(53) 98116-1121">
                  <h5 style={{ color: '#999999' }}>(53) 98116-1121</h5>
                </a>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 my-contact-info__data">
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-fax" />
                  <strong style={{ color: '#999999' }}>Telefone</strong>
                </p>
                <a href="tel:53 3028-5706">
                  <h5 style={{ color: '#999999' }}>(53) 3028-5706</h5>
                </a>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 my-contact-info__data">
              <div className="single-contact-info">
                <p>
                  <i className="fa fa-envelope" />
                  <strong style={{ color: '#999999' }}>E-mail</strong>
                </p>
                <h5 style={{ color: '#999999' }}>moysesimoveis@yahoo.com.br</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="media-container">
        <OurSocialMedias />
      </div>
      <Footer />
    </div>
  );
};

const TextField = styled(MuiTextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: 'var(--main-color-one)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d4d4d4',
    },
    '&:hover fieldset': {
      borderColor: 'var(--main-color-one)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--main-color-one)',
    },
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
});

const FormControlLabel = styled(MuiFormControlLabel)({
  marginTop: '1rem',
  marginRight: '0',

  '.MuiFormControlLabel-label': {
    fontSize: '.7rem',
  },
});

export default Contact;
