import React, { Fragment } from 'react';
import sanitizeHtml from 'sanitize-html';

import './PropertyDescription.scss';

const PropertyDescription = ({ property, post }) => {
	const imobbileFeatures = property.characteristics;

	const control = property?.control;

	const infoTitleCapitalize = ['Estágio', 'Orientação solar', 'Posição'];

	function getStage(stage) {
		if (!stage) {
			return 'Desconhecido';
		} else if (stage === 'construcao') {
			return 'Em construção';
		} else {
			return stage;
		}
	}

	const imobbileInfos = [
		{
			title: 'Negociação e valores',
			sections: [
				{
					subtitle: property.exchange && property.exchange,
				},
			],
		},
		{
			title: 'Espaço',
			sections: [
				{
					subtitle: 'Medidas',
					children: [],
				},
			],
		},
		{
			title: 'Estrutura do imóvel',
			sections: [
				{
					subtitle: 'Tipo de piso',
					children: [],
				},
			],
		},
		{
			title: 'Estágio',
			sections: [{ subtitle: getStage(property.stage) }],
		},
		{
			title: 'Orientação solar',
			sections: [
				{
					subtitle: property.solar_orientation
						? property.solar_orientation
						: 'Desconhecido',
				},
			],
		},
		{
			title: 'Tipo de construção',
			sections: [
				{
					subtitle: property.build_type ? property.build_type : 'Desconhecido',
				},
			],
		},
		{
			title: 'Financiável?',
			sections: [{ subtitle: property.finance === 1 ? 'Sim' : 'Não' }],
		},
		{
			title: 'Posição',
			sections: [
				{ subtitle: property.position ? property.position : 'Desconhecido' },
			],
		},
		{
			title: 'Tipo de vaga',
			sections: [
				{
					subtitle:
						property.vacancy_type && !property.subtype.includes('terreno')
							? property.vacancy_type
							: 'Desconhecido',
				},
			],
		},
		{
			title: 'Andar',
			sections: [
				{
					subtitle: property.floor ? property.floor : undefined,
				},
			],
		},
		{
			title: 'Mobiliado',
			sections: [
				{
					subtitle:
						property.furnished && !property.subtype.includes('terreno')
							? property.furnished
							: undefined,
				},
			],
		},

		{
			title: 'Imediações',
			sections: [
				{
					subtitle: property.address_surrouding
						? property.address_surrouding
						: undefined,
				},
			],
		},
	];

	if (property.front) {
		imobbileInfos[1].sections[0].children.push(
			'Frente: ' + property.front + 'm'
		);
	}
	if (property.left_side) {
		imobbileInfos[1].sections[0].children.push(
			'Lado esquerdo: ' + property.left_side + 'm'
		);
	}

	if (property.right_side) {
		imobbileInfos[1].sections[0].children.push(
			'Lado direito: ' + property.right_side + 'm'
		);
	}

	if (property.backgrounds) {
		imobbileInfos[1].sections[0].children.push(
			'Fundos: ' + property.backgrounds + 'm'
		);
	}

	if (property.floor_type && !property.subtype.includes('terreno')) {
		const floorTypes = property.floor_type.split(';');
		imobbileInfos[2].sections[0].children = floorTypes;
	}

	const description = property && sanitizeHtml(property.description);

	return (
		<div className='container pd-top-responsive'>
			<div className='property-description'>
				<h2 className='property-description__title'>Descrição</h2>
				<section className='property-description__section-description'>
					<div className='property-description__main-container'>
						<div className='property-description__main-container__description'>
							<div
								className='property-description__text paragraph'
								dangerouslySetInnerHTML={{ __html: description }}
							/>
						</div>
						{post && post.length > 0 && (
							<div className='property-description__main-container__banner'>
								<h3>Conheça o bairro</h3>
								<a href='/'>{post[0]?.title}</a>
							</div>
						)}
					</div>
				</section>

				{imobbileFeatures.length > 0 && (
					<>
						<h2 className='property-description__title'>
							Características do Imóvel
						</h2>
						<section className='property-description__section-features'>
							{imobbileFeatures &&
								imobbileFeatures
									.sort((a, b) => {
										return a.localeCompare(b);
									})
									.map((feature, index) => {
										return (
											<strong
												key={index}
												className={`property-description__bold-text ${
													index % 2 === 0 ? 'even' : 'odd'
												}`}
											>
												{feature}
											</strong>
										);
									})}
						</section>
					</>
				)}

				<section className='property-description__section-infos'>
					{imobbileInfos?.map((info, index) => {
						if (property.subtype === 'apartamento' && info.title === 'Espaço') {
							return null;
						}

						if (
							info.title === 'Espaço' &&
							!property.front &&
							!property.left_side &&
							!property.right_side &&
							!property.backgrounds
						) {
							return null;
						}
						if (
							info.title === 'Tipo de vaga' &&
							property.subtype.includes('terreno')
						) {
							return null;
						}

						if (
							info.title === 'Estrutura do imóvel' &&
							property.subtype.includes('terreno')
						) {
							return null;
						}
						if (
							info.title === 'Tipo de construção' &&
							property.subtype.includes('terreno')
						) {
							return null;
						}

						if (
							info.title === 'Posição' &&
							property.subtype.includes('terreno')
						) {
							return null;
						}

						if (
							info.title === 'Orientação solar' &&
							Boolean(control.solar_orientation)
						) {
							return null;
						}

						if (
							info.title === 'Andar' &&
							(!property.floor || property.floor === 0)
						) {
							return null;
						}

						if (
							(info.title === 'Mobiliado' && !property.furnished) ||
							(info.title === 'Mobiliado' &&
								property.subtype.includes('terreno'))
						) {
							return null;
						}

						if (info.title === 'Imediações' && !property.address_surrouding) {
							return null;
						}

						if (
							info.title === 'Negociação e valores' &&
							(!property.exchange || Boolean(control.exchange))
						) {
							return null;
						}

						if (
							(info.title === 'Tipo de vaga' &&
								property.control.garage_vacancy === 1) ||
							(info.title === 'Tipo de vaga' && property.garage_vacancy === '0')
						) {
							return null;
						}

						return (
							<div
								className='property-description__section-infos__info'
								key={index}
							>
								<strong className='property-description__bold-text'>
									{info.title}
								</strong>
								{info?.sections[0]?.children?.length > 0 ||
								!info?.sections[0]?.children ? (
									info.sections.map((section, index) => (
										<Fragment key={index}>
											<p
												className={`property-description__text description-infos ${
													infoTitleCapitalize.includes(info.title) &&
													'capitalize'
												}`}
											>
												{section.subtitle}
											</p>
											{section.children && (
												<ul className='property-description__section-infos__info__list'>
													{section.children.map((child, index) => {
														return (
															<li
																key={index}
																className='property-description__section-infos__info__list__item'
															>
																{child}
															</li>
														);
													})}
												</ul>
											)}
										</Fragment>
									))
								) : (
									<p>Sem informações disponíveis</p>
								)}
							</div>
						);
					})}
				</section>
			</div>
		</div>
	);
};

export default PropertyDescription;
